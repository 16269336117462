import { QuestInfos } from '@t12/common/quest/interfaces/infos-quest.interface';

export const QUEST_FEATURE_KEY = 'quest';

export interface QuestState {
  quests: QuestInfos[];
}
export const initialQuestState: QuestState = {
  quests: [],
};
