import { RecipeDifficulty } from '@t12/common/job/enums/recipe-difficulty.enum';
import { DifficultyRecipeLevel } from '@t12/jobs/types/difficulty-recipe.type';

export const getDifficultyRecipe = (
  playerJobXp: number,
  recipeXp: number,
): DifficultyRecipeLevel => {
  const gapXp = Math.abs(playerJobXp - recipeXp);

  if (gapXp <= RecipeDifficulty.Hard) return 'hard';
  if (gapXp <= RecipeDifficulty.Medium) return 'medium';
  return gapXp < RecipeDifficulty.Easy ? 'easy' : 'none';
};
