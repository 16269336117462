export enum JobCodeToNameEnum {
  alchemist = 'Alchimiste',
  blacksmith = 'Forgeron',
  armorer = 'Armurier',
  tailor = 'Couturier',
  tanner = 'Tanneur',
  herborist = 'Herboriste',
  lumberjack = 'Bûcheron',
  minor = 'Mineur',
  skinner = 'Dépeceur',
  fishing = 'Pêcheur',
  cooking = 'Cuistôt',
  enchanting = 'Enchanteur',
  occultist = 'Occultiste',
}
