import { ElementRef, Injectable } from '@angular/core';
import { Item } from '@t12/common/item/interfaces/item.interface';
import {
  DirectionsPopOver,
  DirectionYPopOver,
  DirectionXPopOver,
} from '@t12/ui/types/direction-popover.type';
import { BehaviorSubject, Observable } from 'rxjs';
import { Popover } from '../../interfaces/popover.interface';

@Injectable({
  providedIn: 'root',
})
export class PopOverManagerService {
  private _popover$: BehaviorSubject<Popover<Item>> = new BehaviorSubject(null);

  // Argument : Content du popover, direction optionnel et élément en survol
  // Résultat : Initialise un popover avec les paramètres requis
  public initPopOver(
    content: Item,
    elementRef: ElementRef,
    direction: DirectionsPopOver = ['right', 'align'],
  ): void {
    if (!content?.description) {
      return null;
    }

    const newPopover: Popover<Item> = {
      content,
      offsets: {
        x: this._getOffsetX(elementRef, direction[0]),
        y: this._getOffsetY(elementRef, direction[1]),
      },
    };
    this._popover$.next(newPopover);
  }

  // Argument : ------
  // Résultat : Retourne le popover actif
  public getPopover$(): Observable<Popover<Item>> {
    return this._popover$.asObservable();
  }

  // Argument : ------
  // Résultat : Supprime le popover actif
  public deletePopOver(): void {
    this._popover$.next(null);
  }

  // Argument : Élément en survol
  // Résultat : Retourne la position verticale du popover
  private _getOffsetY(
    elementRef: ElementRef,
    direction: DirectionYPopOver = 'align',
  ): number {
    const { top, height } = elementRef.nativeElement.getBoundingClientRect();
    const heightPopover = 148;

    switch (direction) {
      case 'above':
        return top - heightPopover - 20;
      case 'below':
        return top + height + 20;
      case 'align':
        return top;
    }
  }

  // Argument : Élément en survol et direction du popover
  // Résultat : Retourne la position horizontale du popover
  private _getOffsetX(
    elementRef: ElementRef,
    direction: DirectionXPopOver = 'left',
  ): number {
    const { width, left } = elementRef.nativeElement.getBoundingClientRect();
    const widthPopover = 308;

    switch (direction) {
      case 'left':
        return left - widthPopover - 20;
      case 'right':
        return left + width + 20;
      case 'align':
        return left;
      case 'center':
        return left - widthPopover / 2;
    }
  }
}
