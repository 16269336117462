import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Gender } from '@t12/common/characters/enums/gender.enum';
import { StatsCharacter } from '@t12/common/characters/interfaces/stats-character.interface';
import { DivinityDetails } from '@t12/common/creation-player/interfaces/divinity-details.interface';
import { PersonalityDetails } from '@t12/common/creation-player/interfaces/personality-details.interface';
import { RaceDetails } from '@t12/common/creation-player/interfaces/race-details.interface';
import { StatsDetails } from '@t12/common/creation-player/interfaces/stats-details.interface';

export const CreationPlayerActions = createActionGroup({
  source: 'CREATION-PLAYER',
  events: {
    init: emptyProps(),
    'Inc step creation': props<{ value: number }>(),
    'Set step creation': props<{ step: number }>(),

    'Load items for creation': emptyProps(),
    'Load items for creation Success': props<{
      races: RaceDetails[];
      stats: StatsDetails[];
      personalities: PersonalityDetails[];
      divinities: DivinityDetails[];
    }>(),
    'Load items for creation failed': props<{ error: HttpErrorResponse }>(),

    'Init names': emptyProps(),
    'Init names error': emptyProps(),
    'Init names success': props<{
      firstNames: string[];
      lastNames: string[];
    }>(),
    'Set name player': props<{ name: string }>(),

    'Set skin player': props<{ skin: string }>(),
    'Set gender player': props<{ gender: Gender }>(),
    'Set gender player success': emptyProps(),

    'Update active race': props<{ race: RaceDetails }>(),

    'Inc allowed number stats': props<{ value: number }>(),
    'Inc player stat': props<{
      stat: keyof StatsCharacter;
      value: 1 | -1;
      nbStatsReq: number;
    }>(),

    'Add player personality': props<{ personality: PersonalityDetails }>(),
    'Remove player personality': props<{ personality: PersonalityDetails }>(),

    'Set player divinity': props<{ divinity: DivinityDetails }>(),
    'Remove player divinity': emptyProps(),

    'create character': emptyProps(),
    'create character success': emptyProps(),
    'create character failure': props<{ error: string }>(),
  },
});
