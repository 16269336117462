import { RacesCode } from '@t12/common/characters/enums/races.enum';
import { IPlayer } from '@t12/common/characters/interfaces/player.interface';
import { DivinityDetails } from '@t12/common/creation-player/interfaces/divinity-details.interface';
import { PersonalityDetails } from '@t12/common/creation-player/interfaces/personality-details.interface';
import { RaceDetails } from '@t12/common/creation-player/interfaces/race-details.interface';
import { StatsDetails } from '@t12/common/creation-player/interfaces/stats-details.interface';

export const CREATION_PLAYER_FEATURE_KEY = 'creation-player';

export interface CreationPlayerState {
  player: IPlayer;
  details: {
    races: RaceDetails[];
    stats: StatsDetails[];
    personalities: PersonalityDetails[];
    divinities: DivinityDetails[];
  };
  totalStats: number;
  step: number;
  firstNames: string[];
  lastNames: string[];
}

export const initialCreationPlayerState: CreationPlayerState = {
  player: {
    name: '',
    gender: 'H',
    raceCode: RacesCode.Human,
    personalities: [],
    divinity: null,
    stats: {
      for: 8,
      con: 8,
      dex: 8,
      int: 8,
      sag: 8,
      cha: 8,
    },
    skin: '',
  } as IPlayer,
  details: {
    races: [],
    stats: [],
    personalities: [],
    divinities: [],
  },
  totalStats: 22,
  step: 0,
  firstNames: [],
  lastNames: [],
};
