import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPlayerName } from '@t12/characters/store/selectors/characters.selectors';
import { ChatManagerService } from '@t12/chat/services/chat-manager.service';
import { ChatActions } from '@t12/chat/store/actions/chat.actions';
import {
  getPmPlayerName,
  getScopeChat,
} from '@t12/chat/store/selectors/chat.selectors';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';
import { ChatLogScope } from '@t12/common/chat/enums/chat-log-scope.enums';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display.actions';
import { getHudChat } from '@t12/overlay/store/selectors/hud-display.selectors';
import { isFriend } from '@t12/socials/store/selectors/socials.selectors';
import { getChatPreviewEnabled } from '@t12/user/store/selectors/user.selectors';
import { SocketService } from '@t12/utils/services/socket/socket.service';
import {
  EMPTY,
  filter,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
  take,
} from 'rxjs';

@Injectable()
export class ChatEffects {
  private _addMessage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ChatActions.addMessage),
      withLatestFrom(
        this._store.select(getPlayerName),
        this._store.select(getPmPlayerName),
        this._store.select(getScopeChat),
      ),
      switchMap(([{ text }, playerName, pmName, scopeChat]) =>
        this._store.select(isFriend(pmName)).pipe(
          take(1),
          map((isFriend) => {
            if (!text) return ChatActions.addMessageFailedEmpty();
            if (text.length < 2) return ChatActions.addMessageFailedTooShort();
            if (scopeChat === ChatLogScope.Pm && !isFriend)
              return ChatActions.addMessageFailedNotFriend();
            return ChatActions.addChatLog({
              tab: 'chat',
              name: playerName,
              text,
            });
          }),
        ),
      ),
    ),
  );

  private _addChatLog$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ChatActions.addChatLog),
      withLatestFrom(this._store.select(getScopeChat)),
      map(([{ text, name, tab, kind }, scope]) => {
        const chatLog = this._chatService.initChatLog(name, scope, text, kind);

        return chatLog
          ? ChatActions.addChatLogSuccess({ tab, chatLog, emit: true })
          : null;
      }),
      filter((action) => !!action),
    ),
  );

  private _addMessageFailedEmpty$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ChatActions.addMessageFailedEmpty),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Merci de bien vouloir saisir du texte.',
          ),
        ),
      ),
    { dispatch: false },
  );

  private _addMessageFailedTooShort$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ChatActions.addMessageFailedTooShort),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Message trop court.',
          ),
        ),
      ),
    { dispatch: false },
  );

  private _addMessageFailedNotFriend$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ChatActions.addMessageFailedNotFriend),
      tap(() =>
        this._notificationService.addNotification(
          'error',
          "Vous n'avez pas d'amis qui match avec ce nom",
        ),
      ),
      map(() => ChatActions.removePmPlayer()),
    ),
  );

  private _addChatLogSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ChatActions.addChatLogSuccess),
        filter(({ emit, chatLog }) => emit && chatLog.kind !== ChatLogKind.Log),
        withLatestFrom(
          this._store.select(getScopeChat),
          this._store.select(getPmPlayerName),
        ),
        tap(([{ chatLog }, scope, name]) => {
          if (scope === ChatLogScope.Pm)
            this._socketService.emit('chat', {
              ...chatLog,
              scope,
              name,
            });
          else this._socketService.emit('chat', { ...chatLog, scope });
        }),
      ),
    { dispatch: false },
  );

  private _incNewMessage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ChatActions.receiveChatLog),
      withLatestFrom(
        this._store.select(getHudChat),
        this._store.select(getChatPreviewEnabled),
      ),
      filter(
        ([_, hudChat, chatPreviewEnabled]) => !hudChat && !chatPreviewEnabled,
      ),
      map(() => ChatActions.incNewMessage()),
    ),
  );

  private _deleteActiveLogs$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ChatActions.deleteActiveLogs),
        tap(() => {
          this._notificationService.addNotification(
            'settings',
            'Historique du chat actif effacé.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _showHudChat$ = createEffect(() =>
    this._actions$.pipe(
      ofType(HudDisplayActions.showHud, HudDisplayActions.toggleHud),
      switchMap(({ name }) =>
        name === 'chat' ? of(ChatActions.resetNewMessage()) : EMPTY,
      ),
    ),
  );

  private _receiveChatLog$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ChatActions.receiveChatLog),
      withLatestFrom(
        this._store.select(getHudChat),
        this._store.select(getChatPreviewEnabled),
      ),
      map(([{ chatLog }, hudChat, chatPreviewEnabled]) => {
        if (
          chatLog.kind !== ChatLogKind.Log &&
          !hudChat &&
          chatPreviewEnabled
        ) {
          return HudDisplayActions.showHud({ name: 'chatPreview' });
        }
        return null;
      }),
      filter((action) => !!action),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _chatService: ChatManagerService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _socketService: SocketService,
    private readonly _store: Store,
  ) {}
}
