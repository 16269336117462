import { EquipmentSlot } from '@t12/common/equipments/types/equipment-slot.type';
import { ItemType } from '@t12/common/item/enums/item-type.enum';
import { IJobRecipe } from '@t12/common/job/interfaces/recipe.interface';
import { Workshop } from '@t12/common/job/interfaces/workshop.interface';
import { JobCode } from '@t12/common/job/types/job-code.type';
import { TabJob } from '@t12/jobs/enums/tab-job.enum';

export const JOB_FEATURE_KEY = 'job';

export interface JobState {
  // TODO Remove ?
  activeJobCode?: JobCode;

  activeTab: TabJob;
  recipeFilter?: ItemType | EquipmentSlot;
  recipeFilters: (ItemType | EquipmentSlot)[];
  recipes: IJobRecipe[];
  displayedRecipes: IJobRecipe[];
  currentPage: number;
  maxPage: number;
  workshop?: Workshop;
}

export const initialJobState: JobState = {
  activeTab: TabJob.Jobs,
  recipeFilters: [],
  recipes: [],
  displayedRecipes: [],
  currentPage: 1,
  maxPage: 1,
};
