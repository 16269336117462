export enum SpriteAnimationsX {
  IDLE_STEP_X = -42,
  FIRST_STEP_X = -81,
  SECOND_STEP_X = 0,
  FIRST_PHYSICAL_ATTACK_X = -122,
  SECOND_PHYSICAL_ATTACK_X = -165,
  LAST_PHYSICAL_ATTACK_X = -206,
  FIRST_MAGICAL_ATTACK_X = -248,
  SECOND_MAGICAL_ATTACK_X = -288,
  LAST_MAGICAL_ATTACK_X = -330,
}
