import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Item } from '@t12/common/item/interfaces/item.interface';

// TODO Remplacer le item par itemCode pour le pick et le drop et ajouter amount
export const InventoryActions = createActionGroup({
  source: 'INVENTORY',
  events: {
    'Set active item': props<{ index: number }>(),
    'Reset active item': emptyProps(),
    'Set inventory': props<{ items: Item[] }>(),
    'Order inventory': emptyProps(),

    'Add item in inventory': props<{
      item: Item;
      amount: number;
    }>(),
    'Add items in inventory': props<{
      items: Item[];
    }>(),

    'Remove item in inventory': props<{
      itemCode: string;
      amount: number;
    }>(),

    'Remove items in inventory': props<{
      items: Item[];
    }>(),
    'Pick Item': props<{ item: Item }>(),
    'Pick item success': props<{ item: Item; amount: number }>(),
    'Pick item failed': emptyProps(),

    'Drop Item': props<{ itemCode: string; amount?: number }>(),
    'Drop item success': props<{ item: Item; amount: number }>(),
    'Drop item failed not enough': emptyProps(),
    'Drop item failed tile invalid': emptyProps(),

    'Use Item': props<{ itemCode: string }>(),
    'Use Item failed': props<{ message: string }>(),

    'Consume item': props<{ item: Item }>(),
    'Consume item health': props<{ item: Item }>(),
    'Consume item health fail': emptyProps(),

    'Consume item mana': props<{ item: Item }>(),
    'Consume item mana fail': emptyProps(),

    'Consume item health and mana': props<{ item: Item }>(),
    'Consume item health and mana fail': emptyProps(),

    'Consume item warp': props<{ item: Item }>(),

    'Equip item': props<{ item: Item }>(),
    'Equip item success': props<{ item: Item }>(),
    'Equip item failed': props<{ message: string }>(),

    'Set favorite': props<{ itemCode: string }>(),
    'Set favorite fail no item': emptyProps(),
    'Set favorite fail no more slot': emptyProps(),
    'Set favorite fail wrong kind': emptyProps(),
  },
});
