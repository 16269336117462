import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { ActiveItem } from '@t12/inventory/interfaces/active-item.interface';
import { InventoryState } from '@t12/inventory/store';

const selectRoot = createFeatureSelector<InventoryState>('inventory');

export const getActiveItemIndexInventory = createSelector(
  selectRoot,
  ({ indexInventory }: InventoryState) => indexInventory,
);

export const getPlayerInventory = createSelector(
  selectRoot,
  ({ items }: InventoryState) => items,
);

export const getActiveItemInventory = createSelector(
  selectRoot,
  getActiveItemIndexInventory,
  ({ items }, index) => {
    const activeItem = items[index];
    return {
      ...activeItem,
      price: Math.floor(activeItem?.price / 2),
      displayedStats: activeItem?.stats
        ? Object.entries(activeItem.stats)
        : null,
    } as ActiveItem;
  },
);

export const getPlayerCountItemInventory = (itemCode: string) =>
  createSelector(
    getPlayerInventory,
    (inventory) =>
      inventory
        ?.filter((item) => item.code === itemCode)
        .reduce((total, item) => total + item.amount, 0) || 0,
  );

export const getPlayerItemInventory = (itemCode: string) =>
  createSelector(getPlayerInventory, (inventory) => {
    const foundItem = inventory?.find((item) => item.code === itemCode);
    const amount =
      inventory
        ?.filter((item) => item.code === itemCode)
        .reduce((total, item) => total + item.amount, 0) || 0;
    return foundItem ? ({ ...foundItem, amount } as Item) : undefined;
  });

export const getFreeInventorySlotAmount = (item: Item) =>
  createSelector(getPlayerInventory, (inventory) => {
    return inventory
      .filter((slotItem) => slotItem.code === item.code || !slotItem.code)
      .reduce((total, slot) => total + (item.max - slot.amount), 0);
  });

export const getMapAmountIngredientsRecipe = (ingredients: Item[]) =>
  createSelector(getPlayerInventory, (inventory) => {
    const result = new Map<string, number>();

    ingredients.forEach((ingredient) => {
      const totalAmount = inventory
        .filter((item) => item.code === ingredient.code)
        .reduce((sum, item) => sum + item.amount, 0);

      result.set(ingredient.code, totalAmount);
    });

    return result;
  });
