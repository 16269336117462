import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { Shop } from '@t12/common/shop/interfaces/shop.interface';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/user/services/auth/authentication.service';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { Observable } from 'rxjs';
import { NPCGetShopDto } from '../../../../../../back/src/characters/dto/npc-get-shop.dto';
import { PickItemDto } from '../../../../../../back/src/inventory/dto/pick-item.dto';

@Injectable({
  providedIn: 'root',
})
export class ShopDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
    private readonly _utilsService: UtilsService,
  ) {}

  // Argument :
  // Résultat : Retourne les infos d'un personnage précis en BD selon son id et type
  public getShopNPC(npcCode: string): Observable<Shop> {
    const playerId = this._utilsService.getSelect(getPlayerID);

    const npcGetShopDto: NPCGetShopDto = {
      playerId,
      npcCode,
    };

    return this._http.post(`${environment(`shop`).uri}`, npcGetShopDto, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<Shop>;
  }

  // Arguments : Item à acheter
  // Résultat : Permet d'acheter un objet à un PNJ
  public buyItem(itemCode: string, amount: number) {
    const playerId = this._utilsService.getSelect(getPlayerID);

    const buyItemDto: PickItemDto = {
      playerId,
      itemCode,
      amount,
    };

    return this._http.patch(`${environment(`shop/buy`).uri}`, buyItemDto, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    });
  }

  // Arguments : Item à vendre
  // Résultat : Permet de vendre un objet à un PNJ
  public sellItem(itemCode: string, amount: number) {
    const playerId = this._utilsService.getSelect(getPlayerID);

    const buyItemDto: PickItemDto = {
      playerId,
      itemCode,
      amount,
    };

    return this._http.patch(`${environment(`shop/sell`).uri}`, buyItemDto, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    });
  }
}
