import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getPlayerInventory } from '@t12/inventory/store/selectors/inventory.selectors';
import { HotkeyState } from '../index';

const selectRoot = createFeatureSelector<HotkeyState[]>('hotkeys');

export const getHotkeys = createSelector(
  selectRoot, // Sélecteur pour récupérer les hotkeys
  getPlayerInventory, // Sélecteur pour récupérer l'inventaire du joueur
  (hotkeys: HotkeyState[], inventory) => {
    const counts = hotkeys.map(
      (hotkey) =>
        inventory
          ?.filter((item) => item.code === hotkey.entity.code)
          .reduce((total, item) => total + item.amount, 0) || 0,
    );

    return hotkeys.map((hotkey, index) => ({
      ...hotkey,
      entity: {
        ...hotkey.entity,
        amount: counts[index],
      },
    }));
  },
);

export const getFirstHotkey = createSelector(
  selectRoot,
  getPlayerInventory,
  (hotkeys: HotkeyState[], inventory) => {
    const counts = hotkeys.map(
      (hotkey) =>
        inventory
          ?.filter((item) => item.code === hotkey.entity.code)
          .reduce((total, item) => total + item.amount, 0) || 0,
    );

    return hotkeys.map((hotkey, index) => ({
      ...hotkey,
      entity: {
        ...hotkey.entity,
        amount: counts[index],
      },
    }))[0];
  },
);
export const getHotkeyByItemCode = (itemCode: string) =>
  createSelector(selectRoot, (hotkeys: HotkeyState[]) =>
    hotkeys.find((hotkey) => hotkey.entity.code === itemCode),
  );

export const getHotkeysAmount = createSelector(
  getHotkeys,
  getPlayerInventory,
  (hotkeys: HotkeyState[], inventory) => {
    return hotkeys.map(
      (hotkey) =>
        inventory
          ?.filter((item) => item.code === hotkey.entity.code)
          .reduce((total, item) => total + item.amount, 0) || 0,
    );
  },
);
