import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isNPCOrMonster } from '@t12/characters/constants/is-npc-or-monster.constant';
import { isPlayer } from '@t12/characters/constants/is-player.constant';
import { CharacterManagerService } from '@t12/characters/services/character-manager-service/character-manager.service';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import {
  getPlayer,
  getCharacterById,
  getCharacterInFront,
} from '@t12/characters/store/selectors/characters.selectors';
import { FightManagerService } from '@t12/fight/services/fight-manager/fight-manager.service';
import { HitKind } from '@t12/fight/types/hit-kind.type';
import { PlayerDbService } from '@t12/player/services/player-db/player-db.service';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import {
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
  delay,
  forkJoin,
  filter,
} from 'rxjs';

@Injectable()
export class AttackCharacterEffects {
  private _attack$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.attack),
      switchMap((action) =>
        this._store.select(getCharacterById(action.idCharacter)).pipe(
          take(1),
          map((character) => ({ action, character })),
        ),
      ),
      filter(({ character }) => !character?.dead),
      switchMap(({ action: { idCharacter, attackKind }, character }) => {
        if (
          attackKind === 'physic' ||
          (attackKind === 'magic' &&
            this._characterService.canUseMagic(character))
        ) {
          return [
            CharactersActions.attackFirstStep({
              idCharacter,
              attackKind,
            }),
          ];
        } else {
          return [];
        }
      }),
    ),
  );

  private _attackFirstStep$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.attackFirstStep),
      delay(300),
      tap(({ attackKind }) => {
        if (attackKind === 'magic')
          this._playerDbService.updatePlayer().pipe(take(1)).subscribe();
      }),
      map(({ idCharacter, attackKind }) =>
        CharactersActions.attackSecondStep({ idCharacter, attackKind }),
      ),
    ),
  );

  private _attackSecondStep$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.attackSecondStep),
      delay(100),
      map(({ idCharacter, attackKind }) =>
        CharactersActions.attackThirdStep({ idCharacter, attackKind }),
      ),
    ),
  );

  private _attackThirdStep$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.attackThirdStep),
      delay(276),
      map(({ idCharacter }) => CharactersActions.idleStep({ idCharacter })),
    ),
  );

  private _attackTryToHit$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.attackSecondStep),
      switchMap((action) =>
        forkJoin({
          attacker: this._store
            .select(getCharacterById(action.idCharacter))
            .pipe(take(1)),
          target: this._store
            .select(getCharacterInFront(action.idCharacter))
            .pipe(take(1)),
        }).pipe(map(({ attacker, target }) => ({ attacker, target, action }))),
      ),
      filter(
        ({ attacker }) =>
          isNPCOrMonster(attacker) ||
          (isPlayer(attacker) && attacker.idCharacter === 0),
      ),
      map(({ attacker, target, action }) => {
        const hit = target
          ? this._fightService.tryToHit(attacker, target, action.attackKind)
          : 'miss';

        return target
          ? CharactersActions.attackHit({
              attacker,
              target,
              attackKind: action.attackKind,
              hit,
            })
          : CharactersActions.attackTryToHitFailed({
              attackKind: action.attackKind,
              hit,
            });
      }),
    ),
  );

  private _attackHit$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.attackHit),
      switchMap((action) =>
        forkJoin({
          attacker: this._store
            .select(getCharacterById(action.attacker.idCharacter))
            .pipe(take(1)),
          target: this._store
            .select(getCharacterInFront(action.attacker.idCharacter))
            .pipe(take(1)),
        }).pipe(map(({ attacker, target }) => ({ attacker, target, action }))),
      ),
      map(({ attacker, target, action }) => {
        const dmgAndReduce = this._fightService.getDmgHit(
          attacker,
          target,
          action.attackKind,
          action.hit,
        );
        this._fightService.displayChatLogHit(
          attacker,
          target,
          dmgAndReduce,
          action.attackKind,
          action.hit,
        );
        return CharactersActions.attackDealDamage({
          attacker: attacker,
          target: target,
          damage: dmgAndReduce[0],
        });
      }),
    ),
  );

  private _attackSound$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          CharactersActions.attackHit,
          CharactersActions.attackTryToHitFailed,
        ),
        tap(({ attackKind, hit }) => {
          const soundType = attackKind === 'physic' ? 'hit' : 'spell';
          const randPhysic = (limit: number) =>
            Math.floor(Math.random() * limit);

          if (attackKind === 'physic') {
            const impactSoundMap: Partial<Record<HitKind, number>> = {
              block: 4,
              parry: 5,
              crit: 3,
              hit: 8,
            };

            if (hit in impactSoundMap) {
              const rand = randPhysic(impactSoundMap[hit]);
              this._audioService.playSound('impacts', `${hit}_${rand}`);
              return;
            }
          }

          const rand = randPhysic(attackKind === 'physic' ? 8 : 2);
          this._audioService.playSound('impacts', `${soundType}_${rand}`);
        }),
      ),
    { dispatch: false },
  );

  private _attackDealDamageNPC$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.attackDealDamage),
      switchMap((action) =>
        forkJoin({
          attacker: this._store
            .select(getCharacterById(action.attacker.idCharacter))
            .pipe(take(1)),
          target: this._store
            .select(getCharacterInFront(action.attacker.idCharacter))
            .pipe(take(1)),
        }).pipe(map(({ attacker, target }) => ({ attacker, target, action }))),
      ),
      filter(({ target }) => isNPCOrMonster(target)),
      map(({ attacker, target }) => {
        if (!isNPCOrMonster(target)) return;

        if (target.health > 0) {
          return CharactersActions.addFight({ attacker, target });
        } else {
          return CharactersActions.npcDeath({ attacker, target });
        }
      }),
    ),
  );

  private _attackDealDamagePlayer$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(CharactersActions.attackDealDamage),
        filter(({ target }) => isPlayer(target)),
        withLatestFrom(this._store.select(getPlayer)),
        tap(([{ target }, player]) => {
          if (player.health <= 0) {
            this._playerDbService
              .updatePlayer()
              .pipe(take(1))
              .subscribe(() => {
                this._store.dispatch(CharactersActions.playerDeath());
              });
          } else {
            this._playerDbService.updatePlayer().pipe(take(1)).subscribe();
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _characterService: CharacterManagerService,
    private readonly _fightService: FightManagerService,
    private readonly _playerDbService: PlayerDbService,
    private readonly _store: Store,
  ) {}
}
