import { Component, Input } from '@angular/core';
import { AsyncPipe, NgClass, NgIf, NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [AsyncPipe, NgIf, NgOptimizedImage, NgClass],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.less',
})
export class LoaderComponent {
  @Input() fullSize: boolean;
  @Input() background: boolean;
  @Input() height: number = 42;
  @Input() label: string;
}
