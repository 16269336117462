import { RandomNamesCharacter } from '@t12/creation-player/interfaces/random-names-character.interface';

export const getNamesByGender = (
  randomNames: RandomNamesCharacter,
  gender: string,
) => {
  const firstNames =
    gender === 'H' ? randomNames.firstNameMale : randomNames.firstNameFemale;
  const lastNames =
    gender === 'H' ? randomNames.lastNameMale : randomNames.lastNameFemale;

  return { firstNames, lastNames };
};
