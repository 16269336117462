import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { TabChat } from '@t12/chat/types/tab-chat.type';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';
import { ChatLogScope } from '@t12/common/chat/enums/chat-log-scope.enums';
import { ChatLog } from '@t12/common/chat/interfaces/chat-log.interface';

export const ChatActions = createActionGroup({
  source: 'CHAT',
  events: {
    Init: emptyProps(),
    'Set tab chat': props<{ tab: TabChat }>(),
    'Set scope chat': props<{ scopeChat: ChatLogScope }>(),
    'Set pm player': props<{ name: string }>(),
    'Remove pm player': emptyProps(),

    'Add message': props<{ text: string }>(),
    'Add message failed empty': emptyProps(),
    'Add message failed too short': emptyProps(),
    'Add message failed not friend': emptyProps(),

    'Add chat log': props<{
      tab: TabChat;
      name: string;
      text: string;
      kind?: ChatLogKind;
    }>(),
    'Add chat log success': props<{
      tab: TabChat;
      chatLog: ChatLog;
      emit: boolean;
    }>(),

    'Repeat last message': emptyProps(),

    'Receive chat log': props<{
      tab: TabChat;
      chatLog: ChatLog;
    }>(),

    'Inc new message': emptyProps(),
    'Reset new message': emptyProps(),
    'Delete active logs': emptyProps(),
  },
});
