export enum PointsStatsRace {
  human = 22,
  dwarf = 18,
  tiefling = 18,
  halfling = 18,
  elf = 18,
  eladrin = 18,
  'dragon-born' = 18,
  'half-elf' = 18,
}
