import { createReducer, on } from '@ngrx/store';
import { ChatState, initialChatState } from '@t12/chat/store';
import { ChatLogScope } from '@t12/common/chat/enums/chat-log-scope.enums';
import { ChatActions } from '../actions/chat.actions';

export const ChatReducer = createReducer(
  initialChatState,

  on(ChatActions.init, () => initialChatState),

  on(ChatActions.setTabChat, (chatState: ChatState, { tab }) => ({
    ...chatState,
    activeTab: tab,
  })),

  on(ChatActions.setScopeChat, (chatState: ChatState, { scopeChat }) => ({
    ...chatState,
    scopeChat,
  })),

  on(
    ChatActions.addChatLogSuccess,
    (chatState: ChatState, { tab, chatLog }) => ({
      ...chatState,
      [tab]: [...chatState[tab], chatLog],
    }),
  ),

  on(ChatActions.receiveChatLog, (chatState: ChatState, { tab, chatLog }) => ({
    ...chatState,
    [tab]: [...chatState[tab], chatLog],
  })),

  on(ChatActions.incNewMessage, (chatState: ChatState) => ({
    ...chatState,
    newMessages: Math.min(chatState.newMessages + 1, 99),
  })),

  on(ChatActions.resetNewMessage, (chatState: ChatState) => ({
    ...chatState,
    newMessages: 0,
  })),

  on(ChatActions.deleteActiveLogs, (chatState: ChatState) => ({
    ...chatState,
    [chatState.activeTab]: [],
  })),

  on(ChatActions.setPmPlayer, (chatState: ChatState, { name }) => ({
    ...chatState,
    pmPlayerName: name,
    scopeChat: ChatLogScope.Pm,
  })),

  on(ChatActions.removePmPlayer, (chatState: ChatState) => ({
    ...chatState,
    pmPlayerName: undefined,
    scopeChat: ChatLogScope.Local,
  })),
);
