export enum RacesCode {
  Human = "human",
  Dwarf = "dwarf",
  Tiefling = "tiefling",
  Halfling = "halfling",
  Elf = "elf",
  Eladrin = "eladrin",
  DragonBorn = "dragon-born",
  HalfElf = "half-elf",
}
