export enum ChatLogColor {
  Action = "#e6ac12",
  Bonus = "#28b436",
  Default = "white",
  Event = "#ff9021",
  Log = "#ffe100",
  Malus = "#ff4141",
  Roll = "pink",
  Pm = "#02D5D5FF",
}
