import { Injectable } from '@angular/core';
import { MemoizedSelector, Store } from '@ngrx/store';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private readonly _store: Store) {}

  public getSelect<T>(selector: MemoizedSelector<any, T>): T {
    let select: T;
    this._store
      .select(selector)
      .pipe(take(1))
      .subscribe((value) => {
        select = value;
      });
    return select !== undefined
      ? JSON.parse(JSON.stringify(select))
      : undefined;
  }

  public setPagination(itemsLength: number, nbItem: number): string[] {
    const pagination: string[] = [];
    const nbPageActuTotal: number = Math.ceil(itemsLength / nbItem) || 0;
    for (let i = 0; i < nbPageActuTotal; i++) {
      pagination.push('');
    }
    return pagination;
  }
}
