import { createReducer, on } from '@ngrx/store';
import { bankMaxSize } from '@t12/common/bank/constants/bank-max-size.constant';
import { ItemType } from '@t12/common/item/enums/item-type.enum';
import { dedupe } from '@t12/common/utils/constants/dedupe.constant';
import { nbRecipesPage } from '@t12/jobs/constants/nb-recipes-page.constant';
import { TabJob } from '@t12/jobs/enums/tab-job.enum';
import { JobActions } from '@t12/jobs/store/actions/job.actions';
import { initialJobState, JobState } from '../index';

export const JobReducer = createReducer(
  initialJobState,

  on(JobActions.init, () => ({
    ...initialJobState,
  })),

  on(JobActions.setActiveJob, (jobState: JobState, { jobCode }) => ({
    ...jobState,
    activeJobCode: jobCode,
  })),

  on(JobActions.setActiveTab, (jobState: JobState, { tab }) => ({
    ...jobState,
    activeTab: tab,
  })),

  on(JobActions.learnRecipeSuccess, (jobState: JobState, { recipe }) => {
    const recipes =
      jobState.activeJobCode === recipe.jobCode
        ? [...jobState.recipes, recipe]
        : jobState.recipes;

    return {
      ...jobState,
      recipes,
    };
  }),

  on(JobActions.setRecipeFilter, (jobState: JobState, { filter }) => ({
    ...jobState,
    recipeFilter: filter,
  })),

  on(JobActions.setRecipeFilters, (jobState: JobState, { item }) => {
    const recipeFilters = dedupe(
      jobState.recipes.map(
        (recipe) => recipe.products[0].slot ?? recipe.products[0].type,
      ),
    ).sort();

    const recipeFilter =
      recipeFilters.find((filter) =>
        [item?.slot, item?.type].includes(filter),
      ) ??
      (recipeFilters.includes(ItemType.Miscellaneous)
        ? ItemType.Miscellaneous
        : recipeFilters[0]);

    return {
      ...jobState,
      recipeFilter,
      recipeFilters,
    };
  }),

  on(JobActions.setRecipes, (jobState: JobState, { jobCode, recipes }) => {
    const recipeFilters = dedupe(
      recipes.map(
        (recipe) => recipe.products[0].slot ?? recipe.products[0].type,
      ),
    ).sort();

    const recipeFilter = recipeFilters.find(
      (filter) => filter === ItemType.Miscellaneous || recipeFilters[0],
    );

    return {
      ...jobState,
      recipes,
      recipeFilter,
      recipeFilters,
      activeJobCode: jobCode,
      activeTab: TabJob.Recipes,
      currentPage: 1,
    };
  }),

  on(JobActions.orderedRecipes, (jobState: JobState) => {
    const currentPage = Math.min(jobState.currentPage, bankMaxSize);
    const startIndex = (currentPage - 1) * nbRecipesPage;

    const activeFilter =
      jobState.recipes[0].products[0].slot ||
      jobState.recipes[0].products[0].type;
    const displayedRecipes = jobState.recipes.filter(
      ({ products: [{ type, slot }] }) =>
        [slot, type].includes(jobState.recipeFilter || activeFilter),
    );

    return {
      ...jobState,
      displayedRecipes: displayedRecipes.slice(
        startIndex,
        startIndex + nbRecipesPage,
      ),
      maxPage: Math.ceil(displayedRecipes.length / nbRecipesPage),
    };
  }),

  on(JobActions.incCurrentPage, (jobState: JobState, { inc }) => {
    const newPage = Math.min(
      Math.max(jobState.currentPage + inc, 1),
      jobState.maxPage,
    );

    return {
      ...jobState,
      currentPage: newPage,
    };
  }),

  on(JobActions.setMaxPage, (jobState: JobState, { maxPage }) => ({
    ...jobState,
    maxPage,
  })),

  on(JobActions.openWorkshopSuccess, (jobState: JobState, { workshop }) => ({
    ...jobState,
    workshop,
    activeTab: TabJob.Recipes,
    activeJobCode: workshop.jobCode,
  })),

  on(JobActions.closeWorkshop, (jobState: JobState) => ({
    ...jobState,
    workshop: undefined,
    activeTab: TabJob.Jobs,
    activeJobCode: undefined,
  })),
);
