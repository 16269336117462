import { SpriteAnimationsX } from '@t12/common/characters/enums/sprite-animations-x.enum';
import { IPlayer } from '@t12/common/characters/interfaces/player.interface';
import { Position } from '@t12/common/characters/interfaces/position.interface';
import { applyDeathPenalty } from '@t12/common/player/constants/apply-death-penalty.constant';
import { deathPenaltyGold } from '@t12/common/player/constants/death-penalty-gold.constant';
import { deathPenaltyXp } from '@t12/common/player/constants/death-penalty-xp.constant';
import { lookingSpriteY } from '@t12/common/player/constants/looking-sprite-y.constant';
import { maxHealth } from '@t12/common/player/constants/max-health.constant';
import { maxMana } from '@t12/common/player/constants/max-mana.constant';
import { getTopCharacter } from '@t12/common/characters/constants/get-top-character.constant';
import { getLeftCharacter } from '@t12/common/characters/constants/get-left-character.constant';

export const defaultDeathPlayerState = (
  player: IPlayer,
  worldCode: string,
  { x, y }: Position,
) => {
  return {
    xp: applyDeathPenalty(player.xp, deathPenaltyXp),
    gold: applyDeathPenalty(player.gold, deathPenaltyGold),
    looking: 'down',
    spriteX: SpriteAnimationsX.IDLE_STEP_X,
    spriteY: lookingSpriteY.down,
    health: maxHealth(player.stats.con),
    mana: maxMana(player.stats.int),
    position: { x, y },
    worldCode,
    top: getTopCharacter(y),
    left: getLeftCharacter(x),
    canMove: true,
    dead: false,
  };
};
