import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import { getPlayerById } from '@t12/characters/store/selectors/characters.selectors';
import { ChatManagerService } from '@t12/chat/services/chat-manager.service';
import { ChatActions } from '@t12/chat/store/actions/chat.actions';
import { ChatLogScope } from '@t12/common/chat/enums/chat-log-scope.enums';
import { ChatLog } from '@t12/common/chat/interfaces/chat-log.interface';
import { PlayerActionLeaveWorld } from '@t12/common/player/constants/player-action-leave-world-socket.interface';
import { PlayerActionSocket } from '@t12/common/player/constants/player-action-socket.interface';
import { PlayerFriendRequest } from '@t12/common/socials/interfaces/player-friend-request.interface';
import { PlayerFriend } from '@t12/common/socials/interfaces/player-friend.interface';
import { PlayerLogged } from '@t12/common/socials/interfaces/player-logged.interface';
import { environment } from '@t12/environment';
import { SocialsActions } from '@t12/socials/store/actions/socials.actions';
import { isFriend } from '@t12/socials/store/selectors/socials.selectors';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket: Socket;
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly _chatService: ChatManagerService,
    private readonly _store: Store,
    private readonly _utilsService: UtilsService,
  ) {}

  // Initialiser la connexion socket avec un playerId dynamique
  public initSocket(playerId: number): void {
    const config: SocketIoConfig = {
      url: environment('').ws,
      options: {
        query: {
          playerId: playerId.toString(),
        },
      },
    };

    this.socket = new Socket(config);

    this._store.dispatch(
      CharactersActions.setCanMove({ idCharacter: 0, canMove: true }),
    );
    this._listenForChatActions();
    this._listenForPlayerActions();
    this._listenForPlayerLoggedOutActions();
    this._listenForPlayerLeftWorldInActions();
    this._listenForPlayerLoggedInActions();
    this._listenForPlayerEnterWorldInActions();
    this._listenForPlayerDeathActions();
    this._listenForPlayerSocialsActions();
    this._listenForSocialsFriendAcceptActions();
  }

  public emit(event: string, data: any): void {
    if (!this.socket) return;

    this.socket.emit(event, data);
  }

  public fromEvent<T>(event: string) {
    if (!this.socket) return;

    return this.socket.fromEvent<T>(event);
  }

  public disconnect(): void {
    this.socket.disconnect();
    this.unsubscribeAll();
  }

  private _listenForChatActions() {
    const chatSubscription = this.fromEvent<ChatLog>('chat')
      .pipe()
      .subscribe(({ name, scope, text, kind, time }) => {
        const isPlayerFriend = this._utilsService.getSelect(isFriend(name));

        if (scope === ChatLogScope.Pm && !isPlayerFriend) return;
        const chatLog = this._chatService.initChatLog(
          name,
          scope,
          text,
          kind,
          time,
        );

        this._store.dispatch(
          ChatActions.receiveChatLog({
            tab: 'chat',
            chatLog,
          }),
        );
      });

    this.subscriptions.push(chatSubscription);
  }

  private _listenForPlayerActions() {
    const playerSubscription = this.fromEvent('player').subscribe(
      (data: PlayerActionSocket) => {
        const { idCharacter } = this._utilsService.getSelect(
          getPlayerById(data.id),
        );

        if (data.action.type === 'move')
          this._store.dispatch(
            CharactersActions.move({
              idCharacter,
              direction: data.action.direction,
            }),
          );
        else if (data.action.type === 'attack')
          this._store.dispatch(
            CharactersActions.attack({
              idCharacter,
              attackKind: data.action.attackKind,
            }),
          );
      },
    );
    this.subscriptions.push(playerSubscription);
  }

  private _listenForPlayerLoggedOutActions() {
    const chatSubscription = this.fromEvent('player-logged-out').subscribe(
      (player: PlayerLogged) => {
        this._store.dispatch(SocialsActions.playerLoggedOut({ player }));
      },
    );
    this.subscriptions.push(chatSubscription);
  }

  private _listenForPlayerLeftWorldInActions() {
    const chatSubscription = this.fromEvent('player-leave-world').subscribe(
      ({ id }: PlayerActionLeaveWorld) => {
        this._store.dispatch(CharactersActions.removePlayer({ id }));
      },
    );
    this.subscriptions.push(chatSubscription);
  }

  private _listenForPlayerLoggedInActions() {
    const chatSubscription = this.fromEvent('player-logged-in').subscribe(
      (player: PlayerLogged) => {
        this._store.dispatch(SocialsActions.playerLoggedIn({ player }));
      },
    );
    this.subscriptions.push(chatSubscription);
  }

  private _listenForPlayerEnterWorldInActions() {
    const chatSubscription = this.fromEvent('player-enter-world').subscribe(
      ({ id }: PlayerActionLeaveWorld) => {
        this._store.dispatch(CharactersActions.addPlayer({ id }));
      },
    );
    this.subscriptions.push(chatSubscription);
  }

  public unsubscribeAll() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  private _listenForPlayerDeathActions() {
    const chatSubscription = this.fromEvent('player-death').subscribe(
      ({ id }: PlayerActionSocket) => {
        this._store.dispatch(CharactersActions.setDead({ id, dead: true }));
      },
    );
    this.subscriptions.push(chatSubscription);
  }

  private _listenForPlayerSocialsActions() {
    const socialSubscription = this.fromEvent(
      'player-friend-request',
    ).subscribe((friendRequest: PlayerFriendRequest) => {
      this._store.dispatch(SocialsActions.addFriendRequest({ friendRequest }));
    });
    this.subscriptions.push(socialSubscription);
  }

  private _listenForSocialsFriendAcceptActions() {
    const socialSubscription = this.fromEvent(
      'player-friend-request-accepted',
    ).subscribe((playerFriend: PlayerFriend) => {
      this._store.dispatch(SocialsActions.addFriend({ playerFriend }));
    });
    this.subscriptions.push(socialSubscription);
  }
}
