import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { ChatActions } from '@t12/chat/store/actions/chat.actions';
import { getPmPlayerName } from '@t12/chat/store/selectors/chat.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display.actions';
import { getHudSocials } from '@t12/overlay/store/selectors/hud-display.selectors';
import {
  catchError,
  EMPTY,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
  filter,
} from 'rxjs';
import { SocialsDbService } from '../../../services/socials-db/socials-db.service';
import { SocialsActions } from '../../actions/socials.actions';

@Injectable()
export class FriendsEffects {
  private _showHudSocials$ = createEffect(() =>
    this._actions$.pipe(
      ofType(HudDisplayActions.showHud, HudDisplayActions.toggleHud),
      withLatestFrom(this._store.select(getHudSocials)),
      switchMap(([{ name }, hudSocials]) =>
        name === 'socials' && hudSocials
          ? of(SocialsActions.getFriends())
          : EMPTY,
      ),
    ),
  );

  private _getFriends$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.getFriends),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([_, playerId]) =>
        this._socialsDbService.getPlayerFriends(playerId).pipe(
          map((friends) => SocialsActions.setFriends({ friends })),
          catchError(() => of(SocialsActions.getFriendsFailed())),
        ),
      ),
    ),
  );

  private _getFriendsFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.getFriendsFailed),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Impossible de récupérer la liste de vos amis.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _updateDisplayedFriends$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        SocialsActions.setFriends,
        SocialsActions.incFriendsPage,
        SocialsActions.addFriend,
        SocialsActions.deleteFriendSuccess,
      ),
      map(() => SocialsActions.updateDisplayedFriends()),
    ),
  );

  private _addFriend$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.addFriend),
        tap(({ playerFriend: { name } }) => {
          this._notificationService.addNotification(
            'settings',
            `Demande de ${name} acceptée`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _deleteFriend$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.deleteFriend),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ name }, playerId]) =>
        this._socialsDbService.deletePlayerFriend(playerId, name).pipe(
          map(() => SocialsActions.deleteFriendSuccess({ name })),
          catchError(() => of(SocialsActions.deleteFriendFailed())),
        ),
      ),
    ),
  );

  private _deleteFriendSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.deleteFriendSuccess),
      tap(({ name }) => {
        this._notificationService.addNotification(
          'error',
          `'${name}' a été retiré de vos amis`,
        );
      }),
      withLatestFrom(this._store.select(getPmPlayerName)),
      map(([{ name }, pmPlayerName]) => {
        if (name === pmPlayerName) {
          return ChatActions.removePmPlayer();
        }
        return null;
      }),
      filter((action) => !!action),
    ),
  );

  private _deleteFriendFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.deleteFriendFailed),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur',
          );
        }),
      ),
    { dispatch: false },
  );
  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
    private readonly _socialsDbService: SocialsDbService,
    private readonly _store: Store,
  ) {}
}
