import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { PlayerUpdateEquipmentI } from '@t12/common/player/interfaces/player-update-equipment.interface';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/user/services/auth/authentication.service';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { Subscription, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EquipmentsDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
    private readonly _utilsService: UtilsService,
  ) {}

  // Arguments : Equipment à ajouter
  // Résultat : Ajoute un équipement au personnage
  public addEquipment(equipmentCode: string): Subscription {
    const playerId = this._utilsService.getSelect(getPlayerID);

    const playerUpdateEquipmentDto: PlayerUpdateEquipmentI = {
      playerId,
      equipmentCode,
    };

    return this._http
      .patch(
        `${environment(`player/equipment/add`).uri}`,
        playerUpdateEquipmentDto,
        {
          headers: {
            authorization: `Bearer ${this._authService.getToken()}`,
          },
        },
      )
      .pipe(take(1))
      .subscribe();
  }

  // Arguments : Equipment à retirer
  // Résultat : Retire un équipement au personnage
  public removeEquipment(equipmentCode: string): Subscription {
    const playerId = this._utilsService.getSelect(getPlayerID);

    const playerUpdateEquipmentDto: PlayerUpdateEquipmentI = {
      playerId,
      equipmentCode,
    };

    return this._http
      .patch(
        `${environment(`player/equipment/remove`).uri}`,
        playerUpdateEquipmentDto,
        {
          headers: {
            authorization: `Bearer ${this._authService.getToken()}`,
          },
        },
      )
      .pipe(take(1))
      .subscribe();
  }
}
