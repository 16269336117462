import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Position } from '@t12/common/characters/interfaces/position.interface';

export const PlayerActions = createActionGroup({
  source: 'PLAYER',
  events: {
    Interaction: emptyProps(),
    'Interact tile': props<{ position: Position }>(),
    'Interact success': emptyProps(),
    'Pick item': props<{ position: Position }>(),
    'Get tile description': props<{ position: Position }>(),

    'Interact character': emptyProps(),
    'Init dialog': props<{ position: Position }>(),
    'Next dialog': props<{ position: Position }>(),
  },
});
