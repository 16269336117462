import { createReducer, on } from '@ngrx/store';
import { ProgressStatus } from '@t12/utils/enums/progress-status.enum';
import { EventsActions } from '../actions/events.actions';
import { initialEventState, EventState } from '../index';

export const EventsReducer = createReducer(
  initialEventState,

  on(EventsActions.setEventSteps, (eventState: EventState, { eventSteps }) => ({
    eventSteps,
    progressStatus: ProgressStatus.IN_PROGRESS,
    indexStep: 0,
  })),

  on(EventsActions.nextEvent, (eventState: EventState) => ({
    ...eventState,
    indexStep: eventState.indexStep + 1,
  })),

  on(EventsActions.endEvent, (eventState: EventState) => ({
    eventSteps: undefined,
    indexStep: 0,
    progressStatus: ProgressStatus.DONE,
  })),
);
