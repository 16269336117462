import { createReducer, on } from '@ngrx/store';
import { initialQuestState, QuestState } from '@t12/quest/store';
import { QuestActions } from '../actions/quest.actions';

export const QuestReducer = createReducer(
  initialQuestState,

  on(QuestActions.init, () => initialQuestState),

  on(QuestActions.setQuests, (questState: QuestState, { questsInfos }) => ({
    ...questState,
    quests: questsInfos,
  })),

  on(
    QuestActions.addQuestSuccess,
    (questState: QuestState, { questInfos }) => ({
      ...questState,
      quests: [...questState.quests, questInfos],
    }),
  ),

  on(QuestActions.removeQuest, (questState: QuestState, { questCode }) => ({
    ...questState,
    quests: questState.quests.filter((item) => item.code !== questCode),
  })),

  on(
    QuestActions.incGoals,
    (
      questState: QuestState,
      { questsInfos, amount: amountAdded, goalKind },
    ) => ({
      ...questState,
      quests: questState.quests.map((questInfo) => ({
        ...questInfo,
        goals: questInfo.goals.map((goalItem) =>
          goalItem.kind === goalKind &&
          questsInfos.some((quest) =>
            quest.goals.some(
              (questGoal) => questGoal.entityCode === goalItem.entityCode,
            ),
          )
            ? {
                ...goalItem,
                amount: Math.min(
                  goalItem.amount + amountAdded,
                  goalItem.amountTotal,
                ),
              }
            : goalItem,
        ),
      })),
    }),
  ),

  on(
    QuestActions.setGoals,
    (questState: QuestState, { questsInfos, amount, goalKind }) => ({
      ...questState,
      quests: questState.quests.map((questInfo) => ({
        ...questInfo,
        goals: questInfo.goals.map((goalItem) =>
          goalItem.kind === goalKind &&
          questsInfos.some((quest) =>
            quest.goals.some(
              (questGoal) => questGoal.entityCode === goalItem.entityCode,
            ),
          )
            ? {
                ...goalItem,
                amount: Math.min(amount, goalItem.amountTotal),
              }
            : goalItem,
        ),
      })),
    }),
  ),
);
