import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isNPCOrMonster } from '@t12/characters/constants/is-npc-or-monster.constant';
import { isPlayer } from '@t12/characters/constants/is-player.constant';
import { CharacterManagerService } from '@t12/characters/services/character-manager-service/character-manager.service';
import { TimersManagerService } from '@t12/characters/services/timers-bot/timers-manager.service';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import {
  getCharacterById,
  getCharacterInFront,
  getPlayer,
} from '@t12/characters/store/selectors/characters.selectors';
import { ChatManagerService } from '@t12/chat/services/chat-manager.service';
import { ChatActions } from '@t12/chat/store/actions/chat.actions';
import { calculateXpForNextLevel } from '@t12/common/player/constants/next-level-xp.constant';
import { ActiveTargetService } from '@t12/fight/services/active-target/active-target.service';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { WorldActions } from '@t12/world/store/actions/world-actions';
import {
  filter,
  forkJoin,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';

@Injectable()
export class FightCharacterEffects {
  private _addFight$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.addFight),
      switchMap((action) =>
        forkJoin({
          attacker: this._store
            .select(getCharacterById(action.attacker.idCharacter))
            .pipe(take(1)),
          target: this._store
            .select(getCharacterInFront(action.attacker.idCharacter))
            .pipe(take(1)),
        }).pipe(map(({ attacker, target }) => ({ attacker, target, action }))),
      ),
      filter(({ target, attacker }) => !attacker.dead && !target.dead),
      map(({ attacker, target }) => {
        if (isPlayer(attacker))
          this._activeTargetService.setActiveTarget(target);

        if (isNPCOrMonster(target)) {
          this._timersService.stopTimerMoveByID(target.idCharacter);
          this._characterService.createTurnFightInterval(attacker, target);
        }

        return CharactersActions.setFaceToPlayer({
          idCharacter: target.idCharacter,
        });
      }),
    ),
  );

  // TODO Call action si xp, container defined
  private _rewardFight$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.rewardFight),
      switchMap(({ xp, container }) => {
        return [
          CharactersActions.rewardXp({ xp }),
          CharactersActions.rewardLoot({ container }),
        ];
      }),
    ),
  );

  private _rewardXp$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.rewardXp),
      filter(({ xp }) => xp > 0),
      withLatestFrom(this._store.select(getPlayer)),
      tap(([{ xp, notification = true }]) => {
        if (notification) {
          this._notificationService.addNotification('item', `+${xp} XP`);
        }
      }),
      switchMap(([{ xp }, player]) => {
        const newXp = player.xp + xp;
        const logAction = ChatActions.addChatLog({
          tab: 'fight',
          name: player.name,
          text: `a gagné ${xp} points d'expériences`,
          kind: ChatLogKind.Bonus,
        });

        const xpAction =
          newXp >= calculateXpForNextLevel(player.lvl)
            ? CharactersActions.levelUp({ xp })
            : CharactersActions.addXp({ xp });

        return [logAction, xpAction];
      }),
    ),
  );

  private _rewardLoot$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.rewardLoot),
      filter(({ container }) => container?.items.length > 0),
      map(({ container }) => {
        const { x, y } = container.position;

        return WorldActions.addContainerTile({
          x,
          y,
          container,
        });
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _activeTargetService: ActiveTargetService,
    private readonly _characterService: CharacterManagerService,
    private readonly _chatService: ChatManagerService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
    private readonly _timersService: TimersManagerService,
  ) {}
}
