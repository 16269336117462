import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EventState } from '../index';

const selectRoot = createFeatureSelector<EventState>('events');

export const getEventsProgressStatus = createSelector(
  selectRoot,
  ({ progressStatus }: EventState) => progressStatus,
);

export const getEventsStep = createSelector(
  selectRoot,
  ({ eventSteps, indexStep }: EventState) => eventSteps?.[indexStep],
);
