import { Item } from "../../../item/interfaces/item.interface";

export function sortItemsByTypeNameAmount(a: Item, b: Item): number {
  // Tri par type
  if (a.type < b.type) {
    return -1;
  } else if (a.type > b.type) {
    return 1;
  }

  if (a.lvl < b.lvl) {
    return -1;
  } else if (a.lvl > b.lvl) {
    return 1;
  }

  if (a.price < b.price) {
    return -1;
  } else if (a.price > b.price) {
    return 1;
  }

  if (a.name < b.name) {
    return -1;
  } else if (a.name > b.name) {
    return 1;
  }

  // Tri par quantité
  if (a.amount < b.amount) {
    return 1;
  } else if (a.amount > b.amount) {
    return -1;
  }

  return 0;
}
