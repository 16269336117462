<ng-container *transloco="let t">
  <div class="settings" *ngIf="isVisible">
    <app-corners-half></app-corners-half>
    <div class="wrapper">
      <div class="section-title">
        <div></div>
        <label>{{ t('settings.title') }}</label>
        <div></div>
      </div>

      <div class="details">
        <!-- Information du compte  -->

        <div class="accountInfos">
          <form>
            <div class="item-form">
              <label
                [ngClass]="errorMail ? 'error' : { validate: updateMail }"
                >{{ t('settings.user-infos.' + labelMail) }}</label
              >
              <div class="field" [ngClass]="{ error: errorMail }">
                <svg viewBox="0 0 23.059 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M22.3,5.855a8.972,8.972,0,0,0-2.164-3.076A10.1,10.1,0,0,0,16.755.738,12.562,12.562,0,0,0,12.333,0,12.6,12.6,0,0,0,8.967.441,12.118,12.118,0,0,0,6,1.694,11.87,11.87,0,0,0,3.517,3.655a11.992,11.992,0,0,0-1.9,2.569A12.9,12.9,0,0,0,.42,9.3,14.072,14.072,0,0,0,0,12.767a12.26,12.26,0,0,0,.861,4.719,9.513,9.513,0,0,0,2.49,3.532,11.089,11.089,0,0,0,3.966,2.215A16.989,16.989,0,0,0,12.622,24a17.649,17.649,0,0,0,3.1-.253,12.88,12.88,0,0,0,2.772-.832l-.738-1.78a11.318,11.318,0,0,1-2.36.7,15.278,15.278,0,0,1-2.808.239,14.7,14.7,0,0,1-4.451-.623A9.165,9.165,0,0,1,4.82,19.636a7.872,7.872,0,0,1-2.084-2.924,10.137,10.137,0,0,1-.724-3.944,11.575,11.575,0,0,1,.767-4.241A10.6,10.6,0,0,1,4.914,5.1a9.913,9.913,0,0,1,3.264-2.3,10.15,10.15,0,0,1,4.154-.84,10.045,10.045,0,0,1,3.481.586,8.369,8.369,0,0,1,2.75,1.621,7.442,7.442,0,0,1,1.8,2.454,7.334,7.334,0,0,1,.651,3.1,10.059,10.059,0,0,1-.384,2.888A7,7,0,0,1,19.6,14.765a4.628,4.628,0,0,1-1.52,1.353,3.8,3.8,0,0,1-1.838.47.738.738,0,0,1-.608-.232,1.007,1.007,0,0,1-.188-.651,4.178,4.178,0,0,1,.094-.8c.063-.313.137-.725.224-1.14l1.867-8.356h-2.3l-.275,1.234a4.687,4.687,0,0,0-1.578-1.12,5.084,5.084,0,0,0-2.07-.379,5.37,5.37,0,0,0-2.548.643A6.775,6.775,0,0,0,6.753,7.546,8.591,8.591,0,0,0,5.327,10.2a10.3,10.3,0,0,0-.521,3.33A6.508,6.508,0,0,0,5.139,15.7a4.685,4.685,0,0,0,.926,1.607,3.85,3.85,0,0,0,1.426,1,4.888,4.888,0,0,0,4.017-.2,6.026,6.026,0,0,0,1.867-1.52,2.134,2.134,0,0,0,.789,1.462,2.646,2.646,0,0,0,.789.434,2.876,2.876,0,0,0,.97.159,6.673,6.673,0,0,0,2.844-.608,6.442,6.442,0,0,0,2.265-1.759,8.366,8.366,0,0,0,1.491-2.815,12.317,12.317,0,0,0,.536-3.778A9.613,9.613,0,0,0,22.3,5.855Zm-8.692,6.912a6.354,6.354,0,0,1-.579,1.585,5.069,5.069,0,0,1-.876,1.2,3.624,3.624,0,0,1-1.107.767,3.091,3.091,0,0,1-1.259.268q-2.49,0-2.49-3.141a8.482,8.482,0,0,1,.34-2.432,7.452,7.452,0,0,1,.9-2A4.752,4.752,0,0,1,9.85,7.664a2.755,2.755,0,0,1,1.556-.5,3.958,3.958,0,0,1,1.867.405,4.407,4.407,0,0,1,1.274.984Z"
                  />
                </svg>

                <input
                  type="email"
                  (click)="resetCheck('mail')"
                  [placeholder]="t('email')"
                  required
                  name="email"
                  [(ngModel)]="credentials.email"
                  (ngModelChange)="resetCheck('mail')"
                />
              </div>
            </div>

            <div class="item-form">
              <label
                [ngClass]="errorPw ? 'error' : { validate: updatePassword }"
                >{{ t('settings.user-infos.' + labelPassword) }}</label
              >
              <div class="field" [ngClass]="{ error: errorPw }">
                <svg viewBox="0 0 20.251 28" xmlns="http://www.w3.org/2000/svg">
                  <path
                    transform="translate(-80.994)"
                    d="M91.12,0a8.2,8.2,0,0,0-8.188,8.188v4.677a.22.22,0,0,1-.117.16c-.443.148-.8.276-1.132.4a1.133,1.133,0,0,0-.689,1.012V25.185a1.141,1.141,0,0,0,.684,1.013,25.631,25.631,0,0,0,18.883,0,1.141,1.141,0,0,0,.684-1.013V14.439a1.133,1.133,0,0,0-.689-1.012c-.329-.127-.689-.255-1.131-.4a.22.22,0,0,1-.117-.16V8.188A8.2,8.2,0,0,0,91.12,0Zm-2.23,17.712a2.23,2.23,0,1,1,4.46,0A2.2,2.2,0,0,1,92.235,19.6v3.687a1.115,1.115,0,1,1-2.23,0V19.6A2.2,2.2,0,0,1,88.889,17.712Zm7.6-9.524v4.035a25.56,25.56,0,0,0-10.746,0V8.188a5.373,5.373,0,0,1,10.746,0Z"
                  />
                </svg>

                <input
                  type="password"
                  (click)="resetCheck('pw')"
                  [placeholder]="t('password')"
                  name="password"
                  [(ngModel)]="credentials.password"
                  (ngModelChange)="resetCheck('pw')"
                />
              </div>
            </div>
          </form>

          <app-button
            [kind]="
              credentials.email === '' && credentials.password === ''
                ? 'warning'
                : 'validation'
            "
            [state]="{ disable: !credentials.email && !credentials.password }"
            (click)="updateAccountInfo()"
            [label]="t('settings.save-modifications')"
          >
          </app-button>
        </div>

        <!-- Paramètres de jeux -->

        <div class="game">
          <div class="item">
            <div class="title">
              <label> {{ t('settings.full-width.title') }} </label>
              <input
                type="checkbox"
                [ngModel]="stateFullscreen"
                (click)="fullscreenManagerService.switchFullscreen()"
              />
            </div>
            <p>{{ t('settings.full-width.description') }}</p>
          </div>

          <div class="item">
            <div class="title">
              <label> {{ t('settings.music.title') }}</label>
              <input
                type="checkbox"
                [(ngModel)]="settings.music"
                (click)="switchMusic()"
              />
            </div>
            <p>{{ t('settings.music.description') }}</p>
          </div>

          <div class="item">
            <div class="title">
              <label> {{ t('settings.noises.title') }}</label>
              <input
                type="checkbox"
                [(ngModel)]="settings.noises"
                (click)="switchNoises()"
              />
            </div>
            <p>{{ t('settings.noises.description') }}</p>
          </div>

          <div class="item">
            <div class="title">
              <label> {{ t('settings.chat-preview.title') }}</label>
              <input
                type="checkbox"
                [(ngModel)]="settings.chatPreview"
                (click)="switchChatPreview()"
              />
            </div>
            <p>{{ t('settings.chat-preview.description') }}</p>
          </div>

          <div class="item">
            <div class="title language">
              <div>
                <label> {{ t('settings.language.title') }}</label>
                <app-language-select [update]="true"></app-language-select>
              </div>
              <select
                [(ngModel)]="activeLang"
                (ngModelChange)="onLangChange($event)"
              >
                <option *ngFor="let lang of LANGUAGES" [value]="lang.code">
                  {{ lang.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="full-separator"></div>

      <!-- Paramètres du compte -->

      <div class="accountActions">
        <div class="delete">
          <div class="title">
            <label> {{ t('settings.user.delete-confirmation') }}</label>
            <input type="checkbox" [(ngModel)]="checkDelete" />
          </div>
          <app-button
            [kind]="'warning'"
            [state]="{ disable: !checkDelete }"
            (click)="deleteAccount()"
            [label]="t('settings.user.delete')"
          >
          </app-button>
        </div>

        <div class="logout">
          <app-button (click)="logout()" [label]="t('settings.user.logout')">
          </app-button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
