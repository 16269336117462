import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CreationPlayerState } from '../index';

const selectRoot =
  createFeatureSelector<CreationPlayerState>('creation-player');

export const getPlayerCreation = createSelector(
  selectRoot,
  ({ player }) => player,
);

export const getStepPlayerCreation = createSelector(
  selectRoot,
  ({ step }) => step,
);

export const getTotalStatsAllowed = createSelector(
  selectRoot,
  ({ totalStats }) => totalStats,
);

export const getRacesDetails = createSelector(
  selectRoot,
  ({ details }) => details.races,
);

export const getStatsDetails = createSelector(
  selectRoot,
  ({ details }) => details.stats,
);

export const getPersonalitiesDetails = createSelector(
  selectRoot,
  ({ details }) => details.personalities,
);

export const getDivinitiesDetails = createSelector(
  selectRoot,
  ({ details }) => details.divinities,
);

export const getGenderPlayerCreation = createSelector(
  selectRoot,
  ({ player }) => player.gender,
);

export const getStatsPlayerCreation = createSelector(
  selectRoot,
  ({ player }) => player.stats,
);

export const getPersonalitiesPlayerCreation = createSelector(
  selectRoot,
  ({ player }) => player.personalities,
);

export const getDivinityPlayerCreation = createSelector(
  selectRoot,
  ({ player }) => player.divinity,
);

export const getRaceCodePlayerCreation = createSelector(
  selectRoot,
  ({ player }) => player.raceCode,
);

export const getNamesRacePlayerCreation = createSelector(
  selectRoot,
  ({ firstNames, lastNames }) => ({ firstNames, lastNames }),
);
