<div [ngClass]="{ interaction, active }" class="slot-item {{ item.quality }}">
  <div
    *ngIf="!item.code"
    class="icon"
    [innerHTML]="backgroundsIconSlot[equipmentSlot]"
  ></div>

  <img *ngIf="item?.img" alt="Image de l'objet" src="{{ item.img }}" />

  <p *ngIf="amount > 1">{{ amount }}</p>
  <p *ngIf="!amount && item?.amount > 1">{{ item.amount }}</p>
</div>
