import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/user/services/auth/authentication.service';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { Observable, map } from 'rxjs';
import { PickItemDto } from '../../../../../../back/src/inventory/dto/pick-item.dto';
import { UseItemDto } from '../../../../../../back/src/inventory/dto/use-item.dto';

@Injectable({
  providedIn: 'root',
})
export class InventoryDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
    private readonly _utilsService: UtilsService,
  ) {}

  // Arguments : Item à utiliser
  // Résultat : Permet d'acheter un objet à un PNJ
  public useItem(itemCode: string): Observable<void> {
    const playerId = this._utilsService.getSelect(getPlayerID);

    const useItemDto: UseItemDto = {
      playerId,
      itemCode,
    };

    return this._http
      .patch(`${environment(`inventory/use/item`).uri}`, useItemDto, {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      })
      .pipe(map(() => void 0)) as Observable<void>;
  }

  public dropItem(itemCode: string, amount: number): Observable<void> {
    const playerId = this._utilsService.getSelect(getPlayerID);

    const pickItemDto: PickItemDto = {
      playerId,
      itemCode,
      amount,
    };

    return this._http
      .patch(`${environment(`inventory/drop/item`).uri}`, pickItemDto, {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      })
      .pipe(map(() => void 0)) as Observable<void>;
  }

  public pickItem(itemCode: string, amount: number): Observable<void> {
    const playerId = this._utilsService.getSelect(getPlayerID);

    const pickItemDto: PickItemDto = {
      playerId,
      itemCode,
      amount,
    };

    return this._http
      .patch(`${environment(`inventory/pick/item`).uri}`, pickItemDto, {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      })
      .pipe(map(() => void 0)) as Observable<void>;
  }
}
