import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import {
  getPlayerLvl,
  getPlayerName,
} from '@t12/characters/store/selectors/characters.selectors';
import { ChatManagerService } from '@t12/chat/services/chat-manager.service';
import { ChatActions } from '@t12/chat/store/actions/chat.actions';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { getPlayerItemInventory } from '@t12/inventory/store/selectors/inventory.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display.actions';
import { getHudQuests } from '@t12/overlay/store/selectors/hud-display.selectors';
import { QuestDbService } from '@t12/quest/services/quest-db/quest-db.service';
import { QuestActions } from '@t12/quest/store/actions/quest.actions';
import {
  areQuestGoalsDone,
  getQuestsInfos,
  getQuestsInfosByCode,
  getQuestsInfosByKindAndCode,
} from '@t12/quest/store/selectors/quest.selectors';
import { WorldActions } from '@t12/world/store/actions/world-actions';
import {
  catchError,
  filter,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';

@Injectable()
export class QuestEffects {
  private _getPlayerQuests$ = createEffect(() =>
    this._actions$.pipe(
      ofType(HudDisplayActions.toggleHud),
      withLatestFrom(this._store.select(getHudQuests)),
      filter(([_, hudQuest]) => hudQuest),
      switchMap(() =>
        this._questDbService.getQuestsPlayer().pipe(
          take(1),
          map((questsInfos) => QuestActions.setQuests({ questsInfos })),
          catchError(() => of(QuestActions.setQuestsFailed())),
        ),
      ),
    ),
  );

  private _setQuestsFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(QuestActions.setQuestsFailed),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Impossible de récupérer les quêtes du joueur',
          ),
        ),
      ),
    { dispatch: false },
  );

  private _addQuest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(QuestActions.addQuest),
      switchMap(({ questCode, npc }) =>
        this._questDbService.initQuestPlayer(questCode, npc.code).pipe(
          take(1),
          map((questInfos) => {
            return QuestActions.addQuestSuccess({ questInfos, npc });
          }),
        ),
      ),
    ),
  );

  private _addQuestSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(QuestActions.addQuestSuccess),
      tap(() => {
        this._notificationService.addNotification(
          'quest',
          `Nouvelle quête ajoutée.`,
        );
      }),
      switchMap(({ questInfos, npc: { code } }) =>
        this._store.select(areQuestGoalsDone(questInfos.code)).pipe(
          take(1),
          withLatestFrom(this._store.select(getPlayerName)),
          switchMap(([isDone, name]) => [
            ChatActions.addChatLog({
              tab: 'action',
              name,
              text: ` a débuté la quête "${questInfos.name}".`,
              kind: ChatLogKind.Log,
            }),
            CharactersActions.setQuestIcon({
              codeCharacter: code,
              questIcon: isDone ? 'done' : 'inProgress',
            }),
          ]),
        ),
      ),
    ),
  );

  private _goalQuestKill$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.rewardFight),
      switchMap(({ target }) =>
        this._store
          .select(getQuestsInfosByKindAndCode('kill', target.code))
          .pipe(
            take(1),
            map((questsInfos) =>
              questsInfos.filter(({ goals }) =>
                goals.some(
                  (goal) =>
                    goal.kind === 'kill' && goal.amount !== goal.amountTotal,
                ),
              ),
            ),
          ),
      ),
      map((filteredQuestsInfos) =>
        QuestActions.incGoals({
          questsInfos: filteredQuestsInfos,
          amount: 1,
          goalKind: 'kill',
        }),
      ),
    ),
  );

  private _goalCollectItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.addItemInInventory),
      switchMap((action) => {
        const itemCode = action.item.code;
        const amount = 'itemCode' in action ? -action.amount : action.amount;

        return this._store
          .select(getQuestsInfosByKindAndCode('collect', itemCode))
          .pipe(
            take(1),
            map((questsInfos) => ({ questsInfos, amount, itemCode })),
          );
      }),
      filter(({ questsInfos }) => questsInfos.length > 0),
      switchMap(({ questsInfos, amount, itemCode }) =>
        this._store.select(getPlayerItemInventory(itemCode)).pipe(
          take(1),
          map((item) => ({
            questsInfos,
            amount,
            item,
          })),
        ),
      ),
      map(({ questsInfos, item }) =>
        QuestActions.setGoals({
          questsInfos,
          amount: item.amount,
          goalKind: 'collect',
        }),
      ),
    ),
  );

  private _goalExploration$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.setCharacters),
      withLatestFrom(this._store.select(getQuestsInfos)),
      map(([{ characters }, questsInfos]) => {
        const charactersCode = characters.map((character) => character.code);

        const questsExploration = questsInfos.filter(({ goals }) =>
          goals.some(
            (goal) =>
              goal.kind === 'exploration' &&
              goal.amount < goal.amountTotal &&
              charactersCode.includes(goal.entityCode),
          ),
        );

        return QuestActions.incGoals({
          questsInfos: questsExploration,
          amount: 1,
          goalKind: 'exploration',
        });
      }),
    ),
  );

  private _goalWorldExploration$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.loadWorldSuccess),
      withLatestFrom(this._store.select(getQuestsInfos)),
      map(([{ world }, questsInfos]) =>
        questsInfos.filter(({ goals }) =>
          goals.some(
            ({ kind, entityCode, entityKind, amount, amountTotal }) =>
              kind === 'exploration' &&
              entityCode === world.code &&
              entityKind === 'world' &&
              amount !== amountTotal,
          ),
        ),
      ),
      filter((questsExploration) => !!questsExploration.length),
      map((questsExploration) =>
        QuestActions.incGoals({
          questsInfos: questsExploration,
          amount: 1,
          goalKind: 'exploration',
        }),
      ),
    ),
  );

  private _updateGoalsNotification$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(QuestActions.incGoals),
        mergeMap(({ questsInfos, amount }) =>
          questsInfos.flatMap(({ goals }) =>
            goals.map((goal) => ({ ...goal, amountAdded: amount })),
          ),
        ),
        tap(({ amount: amountGoal, amountTotal, name, amountAdded }) =>
          this._notificationService.addNotification(
            'quest',
            `${amountGoal + amountAdded}/${amountTotal} : ${name}${amountGoal + amountAdded === amountTotal ? ' (terminé)!' : ''}`,
          ),
        ),
      ),
    { dispatch: false },
  );

  private _setGoalsNotification$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(QuestActions.setGoals),
        withLatestFrom(this._store.select(getQuestsInfos)),
        mergeMap(([_, questsInfos]) =>
          questsInfos.flatMap(({ goals }) =>
            goals.map((goal) => ({ ...goal })),
          ),
        ),
        tap(({ amount: amountGoal, amountTotal, name }) =>
          this._notificationService.addNotification(
            'quest',
            `${amountGoal}/${amountTotal} : ${name}${amountGoal === amountTotal ? ' (terminé)!' : ''}`,
          ),
        ),
      ),
    { dispatch: false },
  );

  private _validateQuest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(QuestActions.validateQuest),
      switchMap(({ questCode }) =>
        this._store.select(getQuestsInfosByCode(questCode)).pipe(take(1)),
      ),
      switchMap((questInfos) =>
        this._store.select(areQuestGoalsDone(questInfos.code)).pipe(
          take(1),
          map(() => {
            return QuestActions.validateQuestSuccess({ questInfos });
          }),
          catchError(() => of(QuestActions.validateQuestFail())),
        ),
      ),
    ),
  );

  private _validateQuestSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(QuestActions.validateQuestSuccess),
      switchMap(({ questInfos }) =>
        this._store.select(getQuestsInfosByCode(questInfos.code)).pipe(
          take(1),
          switchMap((questInfo) => {
            const { xp, gold, rewardItems } = questInfo;

            return this._questDbService.validateQuest(questInfos.code).pipe(
              take(1),
              withLatestFrom(this._store.select(getPlayerName)),
              tap(() => {
                this._notificationService.addNotification(
                  'quest',
                  `Quête terminée : ${questInfos.name}`,
                );
              }),
              map(([_, name]) => {
                const itemsRemoved = questInfos.goals
                  .filter((goal) => goal.kind === 'collect')
                  .map((goal) => ({
                    code: goal.entityCode,
                    amount: goal.amountTotal,
                  })) as Item[];

                const rewardText =
                  rewardItems
                    ?.map(({ name, amount }) => `"${name}" x${amount}`)
                    .join(', ') || '';
                const message = ` a terminé la quête "${questInfos.name}" et reçu ${questInfos.gold} G, ${questInfos.xp} XP${rewardText ? ` et ${rewardText}.` : '.'}`;

                return [
                  gold ? CharactersActions.addGold({ gold }) : null,
                  xp
                    ? CharactersActions.rewardXp({ xp, notification: false })
                    : null,
                  rewardItems?.length
                    ? InventoryActions.addItemsInInventory({
                        items: rewardItems,
                      })
                    : null,
                  itemsRemoved?.length
                    ? InventoryActions.removeItemsInInventory({
                        items: itemsRemoved,
                      })
                    : null,
                  ChatActions.addChatLog({
                    tab: 'action',
                    name,
                    text: message,
                    kind: ChatLogKind.Log,
                  }),
                  CharactersActions.setQuestIcon({
                    codeCharacter: questInfos.npcCode,
                    questIcon: undefined,
                  }),
                  QuestActions.getAvailableQuest({
                    npcCode: questInfos.npcCode,
                  }),
                  QuestActions.removeQuest({
                    questCode: questInfos.code,
                  }),
                ].filter((action) => !!action);
              }),
            );
          }),
        ),
      ),
      mergeMap((actions) => actions),
    ),
  );

  private _validateQuestFail$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(QuestActions.validateQuestFail),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _getAvailableQuest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(QuestActions.getAvailableQuest),
      withLatestFrom(this._store.select(getPlayerLvl)),
      switchMap(([{ npcCode }, playerLvl]) =>
        this._questDbService.getAvailableQuestsNpc(npcCode).pipe(
          take(1),
          map((questInfos) => {
            if (!questInfos) return;

            return questInfos.lvl > playerLvl
              ? CharactersActions.setQuestIcon({
                  codeCharacter: questInfos.npcCode,
                  questIcon: 'unAvalaible',
                })
              : CharactersActions.setQuestIcon({
                  codeCharacter: questInfos.npcCode,
                  questIcon: 'available',
                });
          }),
          filter((action) => !!action),
          catchError(() => of(QuestActions.validateQuestFail())),
        ),
      ),
    ),
  );

  private _abandonQuest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(QuestActions.abandonQuest),
      switchMap(({ questsInfos }) => {
        return this._questDbService.abandonQuest(questsInfos.code).pipe(
          take(1),
          switchMap(() => [
            QuestActions.removeQuest({ questCode: questsInfos.code }),
            CharactersActions.setQuestIcon({
              codeCharacter: questsInfos.npcCode,
              questIcon: undefined,
            }),
          ]),
          catchError(() => of(QuestActions.abandonQuestFail())),
        );
      }),
    ),
  );

  private _abandonQuestFail$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(QuestActions.abandonQuestFail),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Vous ne pouvez pas abandonner une quête de la trame principale',
          );
        }),
      ),
    { dispatch: false },
  );

  private _incGoals$ = createEffect(() =>
    this._actions$.pipe(
      ofType(QuestActions.incGoals),
      mergeMap(({ questsInfos }) =>
        from(questsInfos).pipe(
          mergeMap((questInfo) =>
            this._store.select(areQuestGoalsDone(questInfo.code)).pipe(
              take(1),
              filter((isDone) => isDone),
              map(() =>
                CharactersActions.setQuestIcon({
                  codeCharacter: questInfo.npcCode,
                  questIcon: 'done',
                }),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _chatService: ChatManagerService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _questDbService: QuestDbService,
    private readonly _store: Store,
  ) {}
}
