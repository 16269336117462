import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import { getPlayerGold } from '@t12/characters/store/selectors/characters.selectors';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { getFreeInventorySlotAmount } from '@t12/inventory/store/selectors/inventory.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { ShopDbService } from '@t12/shop/services/shop-db/shop-db.service';
import { ShopActions } from '@t12/shop/store/actions/shop.actions';
import {
  getShopItemByCode,
  getShopActiveItem,
} from '@t12/shop/store/selectors/shop.selectors';
import {
  switchMap,
  catchError,
  of,
  tap,
  map,
  take,
  withLatestFrom,
} from 'rxjs';

@Injectable()
export class ShopBuyEffects {
  private _buyItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ShopActions.buyItem),
      withLatestFrom(this._store.select(getShopActiveItem)),
      switchMap(([{ amount }, item]) =>
        this._store.select(getFreeInventorySlotAmount(item)).pipe(
          take(1),
          withLatestFrom(
            this._store.select(getPlayerGold),
            this._store.select(getShopItemByCode(item.code)),
          ),
          map(([freeAmount, playerGold, shopItem]) => {
            if (!shopItem) return ShopActions.buyItemFailShopWrongItem();
            else if (freeAmount < amount)
              return ShopActions.buyItemFailNotEnoughSpace();
            else if (playerGold < item.price * amount)
              return ShopActions.buyItemFailNotEnoughGold({
                amountMissing: item.price * amount - playerGold,
              });
            else return ShopActions.buyItemSuccess({ item, amount });
          }),
        ),
      ),
    ),
  );

  private _buyItemSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ShopActions.buyItemSuccess),
      tap(({ item, amount }) => {
        // Play sound and show notification as side effects
        this._audioService.playSound('miscs', 'gold');
        this._notificationService.addNotification(
          'item',
          `+${amount} ${item.name} -> -${item.price} OR`,
          5000,
          item.img,
        );
      }),
      switchMap(({ item, amount }) =>
        this._shopDbService.buyItem(item.code, amount).pipe(
          take(1),
          switchMap(() => [
            InventoryActions.addItemInInventory({ item, amount }),
            CharactersActions.addGold({ gold: -item.price * amount }),
          ]),
          catchError(() => of(ShopActions.buyItemFail())),
        ),
      ),
    ),
  );

  private _buyItemFail$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ShopActions.buyItemFail),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          ),
        ),
      ),
    { dispatch: false },
  );

  private _buyItemFailShopWrongItem$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ShopActions.buyItemFailShopWrongItem),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            `Ce commerçant ne vend pas cet objet`,
          ),
        ),
      ),
    { dispatch: false },
  );

  private _buyItemFailNotEnoughSpace$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ShopActions.buyItemFailNotEnoughSpace),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            "Vous n'avez plus de place pour ça",
          ),
        ),
      ),
    { dispatch: false },
  );

  private _buyItemFailNotEnoughGold$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ShopActions.buyItemFailNotEnoughGold),
        tap(({ amountMissing }) =>
          this._notificationService.addNotification(
            'error',
            `Il vous manque ${amountMissing} G pour acheter ça`,
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _shopDbService: ShopDbService,
    private readonly _store: Store,
  ) {}
}
