import { EquipmentSlot } from '@t12/common/equipments/types/equipment-slot.type';
import { ItemType } from '@t12/common/item/enums/item-type.enum';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { Shop } from '@t12/common/shop/interfaces/shop.interface';

export const SHOP_FEATURE_KEY = 'shop';

export interface ShopState {
  shops?: Shop;
  activeFilter?: EquipmentSlot | ItemType;
  filters?: (EquipmentSlot | ItemType)[];
  currentPage: number;
  maxPage?: number;
  activeItem?: Item;
  activeItemAmount?: number;
  displayedItems?: Item[];
}
export const shopState: ShopState = {
  currentPage: 1,
  activeItemAmount: 0,
};
