import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { IAddKnowledge } from '@t12/common/job/interfaces/add-job.interface';
import { PlayerJob } from '@t12/common/job/interfaces/player-job.interface';
import { JobCode } from '@t12/common/job/types/job-code.type';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/user/services/auth/authentication.service';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JobDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
    private readonly _utilsService: UtilsService,
  ) {}

  public addJob(itemCode: string): Observable<PlayerJob> {
    const playerId = this._utilsService.getSelect(getPlayerID);

    const addKnowledgeDto: IAddKnowledge = {
      playerId,
      itemCode,
    };

    return this._http.post(`${environment(`job/add`).uri}`, addKnowledgeDto, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<PlayerJob>;
  }

  public getPlayerJobs(): Observable<PlayerJob[]> {
    const playerId = this._utilsService.getSelect(getPlayerID);

    return this._http.get(environment(`job/${playerId}`).uri, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<PlayerJob[]>;
  }

  public removeJob(jobCode: JobCode): Observable<PlayerJob> {
    const playerId = this._utilsService.getSelect(getPlayerID);

    return this._http.delete(
      `${environment(`job/remove/${jobCode}/${playerId}`).uri}`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    ) as Observable<PlayerJob>;
  }
}
