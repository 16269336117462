import { ItemUseType } from "../enums/item-use-type.enum";
import { Item } from "../interfaces/item.interface";

export const getItemUseType = (item: Item): ItemUseType => {
  if (!item.use?.infos) return undefined;
  const hasHealth = item.use.infos.some((info) => info.stat === "health");
  const hasMana = item.use.infos.some((info) => info.stat === "mana");

  if (hasHealth && hasMana && item.use.infos.length === 2)
    return ItemUseType.HEALTH_MANA;
  if (hasHealth && item.use.infos.length === 1) return ItemUseType.HEALTH;
  if (hasMana && item.use.infos.length === 1) return ItemUseType.MANA;
};
