import { ContainerMaterial } from '@t12/common/container/enums/container-material.enum';

export const getOpeningSoundContainer = (
  containerMaterial: ContainerMaterial,
): string => {
  switch (containerMaterial) {
    case ContainerMaterial.HardWood:
      return 'chest_open_0';
    default:
      return '';
  }
};
