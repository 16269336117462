import { EventSteps } from '@t12/common/events/type/event-step.type';
import { ProgressStatus } from '@t12/utils/enums/progress-status.enum';

export const EVENT_FEATURE_KEY = 'events';

export interface EventState {
  progressStatus: ProgressStatus;
  indexStep: number;
  eventSteps?: EventSteps;
}

export const initialEventState: EventState = {
  progressStatus: ProgressStatus.NOT_DONE,
  indexStep: 0,
  eventSteps: [],
};
