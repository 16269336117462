<section
  *ngIf="inventory | async; let inventory"
  [ngClass]="{ shopOpen: isShopOpen }"
  class="inventory"
>
  <app-corners-all></app-corners-all>
  <div class="wrapper">
    <div
      *ngIf="activeItem && activeItem.code && displayInfos"
      class="activeItem"
    >
      <!-- Informations de l'objet -->
      <div class="infos">
        <div class="header">
          <span class="name">{{ activeItem.name }}</span>
          <div class="actions">
            <ng-container *ngIf="activeItem.max > 1">
              <span class="amount">x{{ activeItem.amount }}</span>
              /
              <span class="max">{{ activeItem.max }}</span>
            </ng-container>

            <svg
              (click)="dropItem()"
              class="trash"
              viewBox="0 0 515.56 515.56"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m64.444 451.11c0 35.526 28.902 64.444 64.444 64.444h257.78c35.542 0 64.444-28.918 64.444-64.444v-322.22h-386.67z"
              />
              <path
                d="m322.22 32.222v-32.222h-128.89v32.222h-161.11v64.444h451.11v-64.444z"
              />
            </svg>
          </div>
        </div>

        <!-- Description de l'objet -->
        <p id="desc">
          {{ activeItem.description }}
          <span
            *ngIf="
              activeItem.use?.learn?.requiredXp !== null &&
              activeItem.use?.learn?.requiredXp !== undefined
            "
          >
            (XP requis:
            {{ activeItem.use?.learn?.requiredXp }})
          </span>
        </p>
        <div class="extras">
          <div class="price">
            <p>{{ activeItem.price }}</p>
            <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M224 22v63.46c-31.9 7.25-58.7 20.94-80.3 41.14-33.7 31.5-50.53 74.7-50.53 129.6 0 54.3 16.53 97.3 49.63 129 21.9 20.9 49 35 81.2 42.1V492h64v-59.4c21.5-.6 42.3-3.2 62.7-7.9 23.5-5.3 56.3-13.2 78.2-23.9l-16-163.8H302l-36 58.8h69.6l8 66.2c-16.2 6.2-36.3 7.1-54.6 7.1-33.5 0-59.4-9.8-77.7-29.5-18.3-19.6-27.4-47.4-27.4-83.4 0-36.4 9.4-64.3 28.3-83.8 19-19.6 46-29.4 80.9-29.4 18.9 0 37.7 2.7 56.4 8 18.9 5.3 33.8 13.4 52.8 24.2l-8-72.5c-18.4-8.35-26-12.66-46.9-16.94-18.9-3.94-38.7-6.06-59.4-6.38V22h-64z"
              />
            </svg>
          </div>
          <p
            *ngIf="activeItem.lvl"
            [ngClass]="{
              malus: (playerLvl | async) < activeItem.lvl,
            }"
            class="lvl"
          >
            Nv.
            <span>{{ activeItem.lvl }}</span>
          </p>
        </div>

        <!-- Statistiques de l'objet -->
        <div
          *ngIf="
            activeItem?.displayedStats || activeItem?.use?.infos?.length > 0
          "
          class="stats"
        >
          <ng-container *ngIf="activeItem?.displayedStats">
            <app-slot-stat
              *ngFor="let stat of activeItem?.displayedStats"
              [bonus]="true"
              [label]="stat[0] | uppercase"
              [svg]="svgStats[stat[0]]"
              [value]="stat[1]"
            ></app-slot-stat>
          </ng-container>

          <ng-container *ngIf="activeItem?.use?.infos">
            <app-slot-stat
              *ngFor="let info of activeItem.use.infos"
              [bonus]="true"
              [label]="info.stat | uppercase"
              [svg]="svgInfos[info.stat]"
              [value]="info.value"
            ></app-slot-stat>
          </ng-container>
        </div>
      </div>

      <!-- Boutons d'actions -->
      <div class="buttons">
        <app-button
          (click)="sellItem(activeItem.code)"
          *ngIf="isShopOpen | async"
          [kind]="'validation'"
          [label]="'Vendre'"
        >
        </app-button>

        <app-button
          (click)="useItem(activeItem.code)"
          *ngIf="activeItem.use"
          [kind]="'validation'"
          [label]="'Utiliser'"
        >
        </app-button>

        <app-button
          (click)="useItem(activeItem.code)"
          *ngIf="activeItem?.displayedStats"
          [kind]="'validation'"
          [label]="'Équiper'"
        >
        </app-button>
      </div>
    </div>

    <!-- Slots d'inventaire -->
    <div class="slots">
      <app-slot-item
        (click)="setActiveItem(i)"
        (contextmenu)="onRightClick($event, item.code)"
        *ngFor="let item of inventory; let i = index; trackBy: trackItemByCode"
        [item]="item"
        [interaction]="true"
        [active]="activeItem.code && item.code === activeItem.code"
      >
      </app-slot-item>
    </div>

    <!-- Actions inventaire -->
    <div class="bottomActions">
      <div class="gold">
        <p>{{ playerGold | async }}</p>
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M224 22v63.46c-31.9 7.25-58.7 20.94-80.3 41.14-33.7 31.5-50.53 74.7-50.53 129.6 0 54.3 16.53 97.3 49.63 129 21.9 20.9 49 35 81.2 42.1V492h64v-59.4c21.5-.6 42.3-3.2 62.7-7.9 23.5-5.3 56.3-13.2 78.2-23.9l-16-163.8H302l-36 58.8h69.6l8 66.2c-16.2 6.2-36.3 7.1-54.6 7.1-33.5 0-59.4-9.8-77.7-29.5-18.3-19.6-27.4-47.4-27.4-83.4 0-36.4 9.4-64.3 28.3-83.8 19-19.6 46-29.4 80.9-29.4 18.9 0 37.7 2.7 56.4 8 18.9 5.3 33.8 13.4 52.8 24.2l-8-72.5c-18.4-8.35-26-12.66-46.9-16.94-18.9-3.94-38.7-6.06-59.4-6.38V22h-64z"
          />
        </svg>
      </div>
      <div *ngIf="activeItem">
        <svg
          (click)="setFavorite()"
          *ngIf="itemTypesItemConsumable.includes(activeItem?.type)"
          class="favorite"
          viewBox="0 0 260 245"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m56,237 74-228 74,228L10,96h240" />
        </svg>
      </div>
    </div>
  </div>
</section>
