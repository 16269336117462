import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { HudDisplayState } from '../index';

export const HudDisplayActions = createActionGroup({
  source: 'HUD',
  events: {
    Reset: emptyProps(),
    'Close all hud': emptyProps(),
    'Show hud': props<{ name: keyof HudDisplayState }>(),
    'Hide hud': props<{ name: keyof HudDisplayState }>(),
    'Toggle hud': props<{ name: keyof HudDisplayState }>(),
  },
});
