import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { IJobRecipe } from '@t12/common/job/interfaces/recipe.interface';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { RecipeDbService } from '@t12/jobs/services/recipe-db/recipe-db.service';
import { JobActions } from '@t12/jobs/store/actions/job.actions';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { tap, switchMap, catchError, of, map, take } from 'rxjs';

@Injectable()
export class RecipeEffects {
  private _learnRecipe$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.learnRecipe),
      switchMap(({ item }) =>
        this._recipeDb.learnRecipe(item.code).pipe(
          take(1),
          map((recipe: IJobRecipe) => {
            return JobActions.learnRecipeSuccess({ recipe, item });
          }),
          catchError(({ error }: HttpErrorResponse) => {
            if (
              error.statusCode === 400 &&
              error.message === 'RECIPE_ALREADY_KNOWN'
            ) {
              return of(JobActions.learnRecipeFailAlreadyKnown());
            } else {
              return of(JobActions.learnRecipeFail()); // Generic fail action
            }
          }),
        ),
      ),
    ),
  );

  private _learnRecipeSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.learnRecipeSuccess),
      tap(({ recipe, item }) => {
        this._notificationService.addNotification(
          'job',
          `Nouvelle recette apprise: ${item.name}`,
        );
      }),
      switchMap(({ item: { code: itemCode } }) => [
        InventoryActions.removeItemInInventory({
          itemCode,
          amount: 1,
        }),
      ]),
    ),
  );

  private _learnRecipeFailAlreadyKnown$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(JobActions.learnRecipeFailAlreadyKnown),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Vous connaissez déjà cette recette`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _learnRecipeFail$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(JobActions.learnRecipeFail),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _learnRecipeFailMissingJob$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(JobActions.learnRecipeFailMissingJob),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Vous n'avez pas le métier correspondant!`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _learnRecipeFailNotEnoughXp$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(JobActions.learnRecipeFailNotEnoughXp),
        tap(({ playerJobXp, requiredXp }) => {
          this._notificationService.addNotification(
            'error',
            `Expérience du métier trop basse! ${playerJobXp}<${requiredXp}XP`,
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
    private readonly _recipeDb: RecipeDbService,
  ) {}
}
