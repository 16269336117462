import { createReducer, on } from '@ngrx/store';
import { DialogActions } from '../actions/dialog.actions';
import { DialogState, initialDialogState } from '../index';

export const DialogReducer = createReducer(
  initialDialogState,

  on(DialogActions.init, () => ({
    ...initialDialogState,
  })),

  on(DialogActions.startConversation, (dialogState: DialogState, { npc }) => ({
    ...dialogState,
    character: npc,
  })),

  on(
    DialogActions.startNarration,
    (dialogState: DialogState, { textsDialog, npc }) => ({
      ...dialogState,
      texts: textsDialog,
      character: npc ? npc : undefined,
      index: 0,
    }),
  ),

  on(DialogActions.setDialog, (dialogState: DialogState, { textsDialog }) => ({
    ...dialogState,
    texts: textsDialog,
    index: 0,
  })),

  on(DialogActions.nextMessage, (dialogState: DialogState) => ({
    ...dialogState,
    index: dialogState.index + 1,
  })),

  on(
    DialogActions.nextMessageOption,
    (dialogState: DialogState, { option }) => ({
      ...dialogState,
      texts: [
        {
          text: option.nextTextDialog,
        },
      ],
      index: 0,
    }),
  ),

  on(
    DialogActions.setOptionsValidations,
    (dialogState: DialogState, { options }) => {
      const { index } = dialogState;
      const texts = [...dialogState.texts];
      texts[index] = {
        ...texts[index],
        options,
      };

      return {
        ...dialogState,
        texts,
      };
    },
  ),

  on(DialogActions.endConversationSuccess, (_: DialogState) => ({
    character: null,
    texts: [],
    index: 0,
  })),
);
