export const HOTKEY_FEATURE_KEY = 'hotkeys';

export interface HotkeyState {
  key?: string;
  entity: {
    code: string;
    amount?: number;
    quality: string;
    type: string;
    img: string;
  };
}

export const initialHotkeyState: HotkeyState[] = [];
