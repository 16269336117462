import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import { switchMap, take, mergeMap } from 'rxjs';
import { SocialsActions } from '../../actions/socials.actions';
import { isFriend } from '../../selectors/socials.selectors';

@Injectable()
export class PlayerLoggedEffects {
  private _playerLoggedIn$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.playerLoggedIn),
      switchMap(({ player }) =>
        this._store.select(isFriend(player.name)).pipe(
          take(1),
          mergeMap((isFriend) => [
            CharactersActions.addPlayer({ id: player.id }),
            ...(isFriend ? [SocialsActions.friendLoggedIn({ player })] : []),
          ]),
        ),
      ),
    ),
  );

  private _playerLoggedOut$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.playerLoggedOut),
      switchMap(({ player }) =>
        this._store.select(isFriend(player.name)).pipe(
          take(1),
          mergeMap((isFriend) => [
            CharactersActions.removePlayer({ id: player.id }),
            ...(isFriend ? [SocialsActions.friendLoggedOut({ player })] : []),
          ]),
        ),
      ),
    ),
  );

  private _friendLoggedIn$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.friendLoggedIn),
      switchMap(({ player }) => {
        return [
          SocialsActions.setFriendOnlineStatus({
            name: player.name,
            online: true,
          }),
        ];
      }),
    ),
  );

  private _friendLoggedOut$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.friendLoggedOut),
      switchMap(({ player }) => {
        return [
          SocialsActions.setFriendOnlineStatus({
            name: player.name,
            online: false,
          }),
        ];
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
  ) {}
}
