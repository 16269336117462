import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BankDbService } from '@t12/bank/services/bank-db/bank-db.service';
import { BankActions } from '@t12/bank/store/actions/bank.actions';
import {
  getBankGold,
  getCountItemBank,
  getDepositGold,
  getDepositItem,
} from '@t12/bank/store/selectors/bank.selectors';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { DepositBank } from '@t12/common/bank/interfaces/deposit-bank.interface';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { getFreeInventorySlotAmount } from '@t12/inventory/store/selectors/inventory.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { map, switchMap, take, tap, withLatestFrom } from 'rxjs';

@Injectable()
export class BankDepositFromEffects {
  private _depositFrom$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BankActions.depositFrom),
      switchMap(() =>
        this._store.select(getDepositItem).pipe(
          take(1),
          switchMap((depositItem) =>
            this._store.select(getFreeInventorySlotAmount(depositItem)).pipe(
              take(1),
              withLatestFrom(
                this._store.select(getBankGold).pipe(take(1)),
                this._store
                  .select(getCountItemBank(depositItem.code))
                  .pipe(take(1)),
                this._store.select(getDepositGold).pipe(take(1)),
              ),
              map(([freeSlot, bankGold, amountBank, depositGold]) => {
                if (freeSlot <= 0)
                  return BankActions.depositFromFailedInventoryFull();
                else if (amountBank < depositItem.amount)
                  return BankActions.depositFromFailedNotEnoughItem();
                else if (bankGold < depositGold)
                  return BankActions.depositFromFailedNotEnoughGold();
                else {
                  return BankActions.depositFromSuccess({
                    item: depositItem,
                    gold: depositGold,
                  });
                }
              }),
            ),
          ),
        ),
      ),
    ),
  );

  private _depositFromSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BankActions.depositFromSuccess),
      withLatestFrom(this._store.select(getPlayerID)),
      tap(([{ item, gold }, playerId]) => {
        const depositDto: DepositBank = {
          playerId,
          item: item.code
            ? { code: item.code, amount: item.amount }
            : undefined,
          amountGold: gold > 0 ? gold : undefined,
        };

        this._bankDbService.depositFromBank(depositDto);
      }),
      switchMap(([{ item, gold }]) => {
        return [
          item?.amount > 0
            ? BankActions.depositFromItemSuccess({ item })
            : null,
          gold ? BankActions.depositFromGoldSuccess({ gold }) : null,
        ].filter((action) => !!action);
      }),
    ),
  );

  private _depositFromItemSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BankActions.depositFromItemSuccess),
      tap(({ item }) => {
        this._notificationService.addNotification(
          'item',
          `+${item.amount} ${item.name}`,
          5000,
          item.img,
        );
      }),
      map(({ item }) =>
        InventoryActions.addItemInInventory({
          item,
          amount: item.amount,
        }),
      ),
    ),
  );

  private _depositFromGoldSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BankActions.depositFromGoldSuccess),
      tap(({ gold }) => {
        this._notificationService.addNotification(
          'item',
          `Vous avez récupéré ${gold}G`,
          5000,
        );
      }),
      map(({ gold }) => CharactersActions.addGold({ gold })),
    ),
  );

  private _depositFromFailedInventoryFull$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BankActions.depositFromFailedInventoryFull),
      tap(() =>
        this._notificationService.addNotification(
          'error',
          "Vous n'avez la place nécessaire",
        ),
      ),
    ),
  );

  private _depositFromFailedNotEnoughGold$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BankActions.depositFromFailedNotEnoughGold),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas assez d'or en réserve.",
          ),
        ),
      ),
    { dispatch: false },
  );

  private _depositFromFailedNotEnoughItem$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BankActions.depositFromFailedNotEnoughItem),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas assez d'exemplaire de l'objet",
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _bankDbService: BankDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}
}
