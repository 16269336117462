<button class="{{ kind }}" [ngClass]="state" [disabled]="disabled">
  <div class="corner top-left"></div>
  <div class="corner top-right"></div>
  <div class="corner bottom-left"></div>
  <div class="corner bottom-right"></div>
  <div class="container">
    <ng-container *ngIf="loading !== LoadingStatus.IN_PROGRESS">
      <img
        *ngIf="svgName"
        src="assets/images/svg/{{ svgName }}.svg"
        alt="Picto svg du boutton"
      />
      <label>{{ label }}</label>
    </ng-container>

    <app-loader
      [height]="24"
      [label]="labelLoadingStatus"
      *ngIf="loading === LoadingStatus.IN_PROGRESS"
    ></app-loader>
  </div>
</button>
