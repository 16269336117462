export const CraftsmanshipJobs = [
  'alchemist',
  'blacksmith',
  'armorer',
  'tailor',
  'tanner',
  'herborist',
  'occultist',
  'enchanting',
  'lumberjack',
  'minor',
  'skinner',
];
