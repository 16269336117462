<div
  class="slot-stat"
  [ngClass]="{ reverse: reverse }"
  *transloco="let t; read: 'charactersCreationStatistics'"
>
  <div
    class="slot"
    [ngClass]="{
      'no-border': !border,
      malus: bonus && value < 0,
      bonus: bonus && value > 0,
    }"
  >
    <div *ngIf="border" class="corner top-left"></div>
    <div *ngIf="border" class="corner top-right"></div>
    <div *ngIf="border" class="corner bottom-left"></div>
    <div *ngIf="border" class="corner bottom-right"></div>
    <div
      *ngIf="svg"
      [innerHTML]="svg"
      class="picto"
      [ngClass]="label | lowercase"
    ></div>
    <img
      *ngIf="imgName"
      class="picto"
      src="assets/images/svg/{{ imgName }}.svg"
      alt="Image de la stat"
    />
    <img
      *ngIf="urlImg"
      class="picto"
      src="{{ urlImg }}"
      alt="Image de la stat"
    />
  </div>
  <label *ngIf="label"
    >{{ t('stat.' + (label | lowercase) + '.short-name') }}
  </label>
  <div class="values">
    <span class="value" *ngIf="value !== undefined && !hideValue">
      <span *ngIf="value > 0 && showOperator">+</span> {{ value }}</span
    >
    <span class="max" *ngIf="maxValue">/{{ maxValue }}</span>
  </div>
</div>
