import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { itemTypesItemConsumable } from '@t12/common/item/constants/item-types-consumable.constant';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { getPlayerItemInventory } from '@t12/inventory/store/selectors/inventory.selectors';
import { hotkeyMaxConstant } from '@t12/key-commands/constants/hotkey-max.constant';
import { HotkeyState } from '@t12/key-commands/store/hotkey';
import { HotkeyActions } from '@t12/key-commands/store/hotkey/actions/hotkey.actions';
import {
  getHotkeyByItemCode,
  getHotkeys,
} from '@t12/key-commands/store/hotkey/selectors/hotkey.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { map, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { isUserDeviceMobile } from '@t12/user/store/selectors/user.selectors';

@Injectable()
export class InventoryFavoriteEffects {
  private _setFavorite$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.setFavorite),
      switchMap(({ itemCode }) =>
        this._store.select(getPlayerItemInventory(itemCode)).pipe(
          take(1),
          withLatestFrom(
            this._store.select(getHotkeys),
            this._store.select(getHotkeyByItemCode(itemCode)),
            this._store.select(isUserDeviceMobile),
          ),

          map(([item, hotkeys, hotkeyExist, isMobileDevice]) => {
            if (!item) return InventoryActions.setFavoriteFailNoItem();

            const hotkey: HotkeyState = {
              entity: {
                type: item.type,
                code: item.code,
                quality: item.quality,
                img: item.img,
              },
            };

            if (isMobileDevice) {
              if (hotkeys.length === 0)
                return HotkeyActions.addHotkey({ hotkey });
              else return HotkeyActions.setHotkeyAt({ hotkey, index: 0 });
            } else if (hotkeyExist)
              return HotkeyActions.removeHotkey({
                code: hotkeyExist.entity.code,
              });
            else if (hotkeys.length >= hotkeyMaxConstant && !hotkeyExist)
              return InventoryActions.setFavoriteFailNoMoreSlot();
            else if (!itemTypesItemConsumable.includes(item.type))
              return InventoryActions.setFavoriteFailWrongKind();
            else {
              if (!isMobileDevice) {
                return HotkeyActions.addHotkey({ hotkey });
              } else {
                return HotkeyActions.setHotkeyAt({ hotkey, index: 0 });
              }
            }
          }),
        ),
      ),
    ),
  );

  private _setFavoriteFailNoItem$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.setFavoriteFailNoItem),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas cet objet",
          );
        }),
      ),
    { dispatch: false },
  );

  private _setFavoriteFailNoMoreSlot$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.setFavoriteFailNoMoreSlot),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Vous n'avez plus de raccourcis libre",
          );
        }),
      ),
    { dispatch: false },
  );

  private _setFavoriteFailWrongKind$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.setFavoriteFailWrongKind),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Vous ne pouvez pas mettre cet objet en raccourcis',
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}
}
