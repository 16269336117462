import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { PopOverManagerService } from '@t12/overlay/services/pop-over/pop-over-manager.service';
import { DirectionsPopOver } from '../types/direction-popover.type';

@Directive({
  selector: '[appPopover]',
  standalone: true,
})
export class PopoverDirective {
  @Input() public content: Item;
  @Input() public directionsPopover: DirectionsPopOver;

  constructor(
    private _elementRef: ElementRef,
    private _popOverService: PopOverManagerService,
  ) {}

  @HostListener('mouseenter') public mouseover(): void {
    this._popOverService.initPopOver(
      this.content,
      this._elementRef,
      this.directionsPopover,
    );
  }

  @HostListener('mouseleave') public mouseleave(): void {
    this._popOverService.deletePopOver();
  }
}
