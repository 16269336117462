import { RankJobXp } from '@t12/common/job/enums/rank-job-xp.enum';

export const canUseWorkshop = (
  jobXp: number,
): {
  apprentice: boolean;
  devotee: boolean;
  expert: boolean;
  master: boolean;
} => ({
  apprentice: jobXp >= RankJobXp.apprentice || jobXp < RankJobXp.apprentice,
  devotee: jobXp >= RankJobXp.devotee,
  expert: jobXp >= RankJobXp.expert,
  master: jobXp >= RankJobXp.master,
});
