export enum ChatLogKind {
  Unknown = "unknown",
  Talk = "talk",
  Yell = "yell",
  Roll = "roll",
  Action = "action",
  All = "all",
  Pm = "pm",
  Party = "party",
  Log = "log",
  Bonus = "bonus",
  Malus = "malus",
}
