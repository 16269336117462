import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { isNPCOrMonster } from '@t12/characters/constants/is-npc-or-monster.constant';
import { isPlayer } from '@t12/characters/constants/is-player.constant';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import { getCharacters } from '@t12/characters/store/selectors/characters.selectors';
import { Monster } from '@t12/common/characters/interfaces/monster.interface';
import { NPC } from '@t12/common/characters/interfaces/npc.interface';
import { Character } from '@t12/common/characters/types/character.type';
import { Looking } from '@t12/common/characters/types/looking.type';
import { maxMana } from '@t12/common/player/constants/max-mana.constant';
import { AttackKind } from '@t12/fight/types/attack-kind.type';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { LoadingStatus } from '@t12/utils/enums/loading-status.enum';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { positionInFrontClose } from '@t12/world/constants/position-in-front-close.constant';
import { WorldGeneratorService } from '@t12/world/services/world-generator/world-generator.service';
import { getWorldLoadingStatus } from '@t12/world/store/selector/world.selectors';
import { TimersManagerService } from '../timers-bot/timers-manager.service';

@Injectable({
  providedIn: 'root',
})
export class CharacterManagerService {
  private _direction: Looking[] = ['up', 'right', 'down', 'left'];

  constructor(
    private readonly _worldService: WorldGeneratorService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
    private readonly _timerService: TimersManagerService,
    private readonly _utils: UtilsService,
  ) {}

  public canMove(character: Character, direction: Looking): boolean {
    const worldIsLoaded = this._utils.getSelect(getWorldLoadingStatus);

    return (
      character.canMove &&
      character.dead === false &&
      worldIsLoaded === LoadingStatus.LOADED &&
      this._worldService.canStepOnTile({ ...character, looking: direction })
    );
  }

  // Argument : Attaquant et la cible
  // Résultat : Ajoute un interval de patrouille pour un NPC
  public createPatrolInterval(idCharacter: number): void {
    const timersMove = this._timerService.getTimersMove();

    if (!timersMove.has(idCharacter)) {
      const randTimeMove = Math.floor(Math.random() * 4000 + 4000);
      timersMove.set(
        idCharacter,
        window.setInterval(
          () => this._moveBotRandom(idCharacter),
          randTimeMove,
        ),
      );
    }
  }

  // Argument : Attaquant et la cible
  // Résultat : Crée un interval de combat pour un NPC
  public createTurnFightInterval(
    attacker: Character,
    target: NPC | Monster,
  ): void {
    const timersFight = this._timerService.getTimersFight();
    if (!timersFight.has(target.idCharacter) && !target.dead) {
      this._store.dispatch(
        CharactersActions.setState({
          idCharacter: target.idCharacter,
          state: 'fight',
        }),
      );

      timersFight.set(
        target.idCharacter,
        window.setInterval(() => this._turnFightNPC(target, attacker), 1000),
      );
    }
  }

  // Argument : Personnage, personnage cible
  // Résultat : Tourne le personnage cible face au personnage
  public setFaceTo(character1: Character, character2: Character): Looking {
    const { position: position1 } = character1;
    const { idCharacter, position: position2 } = character2;

    if (!position1 || !position2) return;

    const directions = {
      up: position1.y < position2.y,
      down: position1.y > position2.y,
      left: position1.x < position2.x,
      right: position1.x > position2.x,
    };

    const looking = Object.keys(directions).find(
      (key) => directions[key],
    ) as Looking;

    this._store.dispatch(
      CharactersActions.setLooking({ idCharacter, looking }),
    );
    return looking;
  }

  // Argument : Attaquant NPC et la cible
  // Résultat : Prépare le combat et son déroulement
  private _turnFightNPC(attacker: NPC | Monster, target: Character): void {
    const characters = this._utils.getSelect(getCharacters);
    const foundCharacters = [target, attacker].map((obj) =>
      characters.find((character) => character.idCharacter === obj.idCharacter),
    );
    const foundAttacker = foundCharacters[1];
    if (!isNPCOrMonster(foundAttacker)) {
      this._timerService.stopTimerFightByID(foundAttacker.idCharacter);
      return;
    }

    const foundTarget = foundCharacters[0] as Character; // Assuming target can be any Character
    const directionTarget = this.setFaceTo(foundTarget, foundAttacker);
    const { x, y } = positionInFrontClose(
      foundAttacker.position.x,
      foundAttacker.position.y,
      foundAttacker.looking,
    );
    const targetIsAlive = foundTarget.health > 0;
    const { position } = foundTarget;
    const { idCharacter } = foundAttacker;

    // Attaque le joueur
    if (y === position.y && x === position.x && targetIsAlive) {
      this._store.dispatch(
        CharactersActions.attack({
          idCharacter,
          attackKind: this._chooseAttackNPC(foundAttacker),
        }),
      );
    } else if (targetIsAlive) {
      // Poursuit le joueur
      this._store.dispatch(
        CharactersActions.move({
          idCharacter,
          direction: directionTarget,
        }),
      );
    } else if (!targetIsAlive) {
      // Joueur/Cible mort
      this._store.dispatch(
        CharactersActions.setState({
          idCharacter,
          state: 'passive',
        }),
      );
      this._timerService.stopTimerFightByID(foundAttacker.idCharacter);
      this.createPatrolInterval(foundAttacker.idCharacter);
    }
  }

  // Argument : Attaquant NPC
  // Résultat : Choisis le type de la prochaine attaque du NPC, physique ou magique (max 40% chance magic)
  private _chooseAttackNPC(attacker: NPC | Monster): AttackKind {
    const roll = Math.floor(Math.random() * 100);
    const useMagic =
      attacker.stats.int > 0 ? Math.min(40, 20 + 0.3 * attacker.stats.int) : -1;

    return this.canUseMagic(attacker) && roll <= useMagic ? 'magic' : 'physic';
  }

  // Argument : Personnage à vérifier
  // Résultat : Vérifie que le personnage a assez de mana pour faire une attaque magique.
  public canUseMagic(character: Character): boolean {
    if (!character) return false;

    const costMana = Math.max(
      Math.floor(maxMana(character.stats.int) * 0.06),
      2,
    );
    const enoughMana = character.mana >= costMana;

    if (!enoughMana && isPlayer(character) && character.idCharacter === 0) {
      this._notificationService.addNotification(
        'error',
        "Vous n'avez pas assez de mana!",
      );
    }

    return enoughMana;
  }

  // Argument : Personnage à déplacer
  // Résultat : Déplace de manière aléatoire un personnage ordinateur
  private _moveBotRandom(IdCharacter: number): void {
    const randomIndex = Math.floor(Math.random() * this._direction.length);
    let characterFound = this._utils
      .getSelect(getCharacters)
      .find((character) => character.idCharacter === IdCharacter);
    this._store.dispatch(
      CharactersActions.setLooking({
        idCharacter: characterFound.idCharacter,
        looking: this._direction[randomIndex],
      }),
    );
    characterFound = this._utils
      .getSelect(getCharacters)
      .find((character) => character.idCharacter === IdCharacter);

    if (
      characterFound.canMove &&
      this._worldService.canStepOnTile(characterFound)
    ) {
      this._store.dispatch(
        CharactersActions.move({
          idCharacter: characterFound.idCharacter,
          direction: characterFound.looking,
        }),
      );
    }
  }
}
