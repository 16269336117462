import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Item } from '@t12/common/item/interfaces/item.interface';

export const EquipmentsActions = createActionGroup({
  source: 'EQUIPMENTS',
  events: {
    'Equip Item': props<{ item: Item }>(),
    'Equip item failed already have': emptyProps(),

    'Unequip Item': props<{ item: Item }>(),
    'Unequip item failed no place': emptyProps(),
  },
});
