<div class="loading-wrapper" [ngClass]="{ 'full-size': fullSize }">
  <img
    src="assets/images/gif/loading.gif"
    alt="Chargement en cours..."
    class="loading"
    [attr.height]="height"
    [attr.width]="height"
    loading="lazy"
  />

  <p>{{ label }}</p>
</div>
