import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CharacterState } from '@t12/characters/store';
import { getCharacters } from '@t12/characters/store/selectors/characters.selectors';
import { PlayerJob } from '@t12/common/job/interfaces/player-job.interface';
import { JobCode } from '@t12/common/job/types/job-code.type';
import { getDifficultyRecipe } from '@t12/jobs/constants/get-difficulty-recipe.constant';
import { getKindJobByCode } from '@t12/jobs/constants/get-job-kind-by-code.constant';
import { JobState } from '@t12/jobs/store';

const selectRoot = createFeatureSelector<JobState>('job');

export const getJobState = createSelector(selectRoot, (job: JobState) => job);

export const getActiveJobCode = createSelector(
  selectRoot,
  ({ activeJobCode }) => activeJobCode,
);

export const getActiveTabJob = createSelector(
  selectRoot,
  ({ activeTab }) => activeTab,
);

export const getRecipes = createSelector(selectRoot, ({ recipes }) => recipes);

export const getRecipeFilters = createSelector(
  selectRoot,
  ({ recipeFilters }) => recipeFilters,
);

export const getRecipeFilter = createSelector(
  selectRoot,
  ({ recipeFilter }) => recipeFilter,
);

export const getDisplayedRecipes = createSelector(
  selectRoot,
  ({ displayedRecipes }) => displayedRecipes,
);

export const getRecipesCurrentPage = createSelector(
  selectRoot,
  ({ currentPage }) => currentPage,
);

export const getRecipesMaxPage = createSelector(
  selectRoot,
  ({ maxPage }) => maxPage,
);

export const getWorkshop = createSelector(
  selectRoot,
  ({ workshop }) => workshop,
);

export const getIsWorkshopOpen = createSelector(
  selectRoot,
  ({ workshop }) => !!workshop,
);

export const getActiveJob = createSelector(
  getCharacters,
  getActiveJobCode,
  (characters: CharacterState, jobCode): PlayerJob | undefined => {
    const jobKind = getKindJobByCode(jobCode);
    return characters[0]?.jobs[jobKind]?.find((job) => job.code === jobCode);
  },
);

export const getPlayerJobWithCode = (jobCode: JobCode) =>
  createSelector(getCharacters, (characters: CharacterState) => {
    const jobKind = getKindJobByCode(jobCode);
    return characters[0]?.jobs[jobKind].find((job) => job.code === jobCode);
  });

export const getRecipeDifficulty = (recipeXp: number) =>
  createSelector(getActiveJob, (job: PlayerJob) => {
    return getDifficultyRecipe(job.xp, recipeXp);
  });
