import { Item } from "../../item/interfaces/item.interface";
import { qualityOrderItem } from "../../utils/constants/quality-order-item.constant";

export const bankSortItems = (a: Item, b: Item) => {
  // Comparaison par item.quality
  if (qualityOrderItem[a.quality] < qualityOrderItem[b.quality]) {
    return -1;
  } else if (qualityOrderItem[a.quality] > qualityOrderItem[b.quality]) {
    return 1;
  }

  if (a.type < b.type) {
    return -1;
  } else if (a.type > b.type) {
    return 1;
  }

  if (a.lvl < b.lvl) {
    return -1;
  } else if (a.lvl > b.lvl) {
    return 1;
  }

  if (a.price < b.price) {
    return -1;
  } else if (a.price > b.price) {
    return 1;
  }

  if (a.name < b.name) {
    return -1;
  } else if (a.name > b.name) {
    return 1;
  }

  // Tri par quantité
  if (a.amount < b.amount) {
    return 1;
  } else if (a.amount > b.amount) {
    return -1;
  }

  return 0;
};
