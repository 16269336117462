import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IJobRecipe } from '@t12/common/job/interfaces/recipe.interface';
import { RecipeDbService } from '@t12/jobs/services/recipe-db/recipe-db.service';
import { JobActions } from '@t12/jobs/store/actions/job.actions';
import { getRecipes } from '@t12/jobs/store/selectors/job.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import {
  switchMap,
  catchError,
  of,
  map,
  take,
  tap,
  withLatestFrom,
  filter,
} from 'rxjs';

@Injectable()
export class ListRecipesEffects {
  private _learnRecipe$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.displayedRecipes),
      switchMap(({ jobCode }) =>
        this._recipeDb.getPlayerJobRecipes(jobCode).pipe(
          take(1),
          map((recipes: IJobRecipe[]) => {
            return JobActions.setRecipes({ jobCode, recipes });
          }),
          catchError(({ error }: HttpErrorResponse) => {
            return of(JobActions.setRecipesFail()); // Generic fail action
          }),
        ),
      ),
    ),
  );

  private _setRecipesFail$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(JobActions.setRecipesFail),
        tap(() => {
          this._notificationService.addNotification(
            'job',
            'Impossible de récupérer les recettes connues.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _setRecipes$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        JobActions.incCurrentPage,
        JobActions.setRecipeFilter,
        JobActions.setRecipeFilters,
      ),
      withLatestFrom(this._store.select(getRecipes)),
      filter(([_, recipes]) => recipes.length > 0),
      map(() => JobActions.orderedRecipes()),
    ),
  );

  private _setRecipesFilters$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.setRecipes),
      map(() => JobActions.setRecipeFilters({})),
    ),
  );

  private _setRecipesFilters2$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.learnRecipeSuccess),
      map(({ recipe }) =>
        JobActions.setRecipeFilters({ item: recipe.products[0] }),
      ),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
    private readonly _recipeDb: RecipeDbService,
    private readonly _store: Store,
  ) {}
}
