export const moveKey = {
  up: ['z', 'arrowup'],
  right: ['d', 'arrowright'],
  down: ['s', 'arrowdown'],
  left: ['q', 'arrowleft'],
};

export const attackKey = {
  physic: ['a'],
  magic: ['e'],
};

export const interactionKey = {
  interaction: [' '],
};
