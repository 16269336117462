import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RankJobXp } from '@t12/common/job/enums/rank-job-xp.enum';
import { canUseWorkshop } from '@t12/jobs/constants/can-use-workshop.constant';
import { JobCodeToNameEnum } from '@t12/jobs/enums/job-code-to-name.enum';
import { JobActions } from '@t12/jobs/store/actions/job.actions';
import {
  getPlayerJobWithCode,
  getWorkshop,
  getRecipes,
} from '@t12/jobs/store/selectors/job.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display.actions';
import { getHudJob } from '@t12/overlay/store/selectors/hud-display.selectors';
import { map, switchMap, tap, take, concatMap, withLatestFrom } from 'rxjs';

@Injectable()
export class WorkshopEffects {
  private _openWorkshop$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.openWorkshop),
      withLatestFrom(
        this._store.select(getHudJob),
        this._store.select(getWorkshop),
      ),
      switchMap(([{ workshop }]) =>
        this._store.select(getPlayerJobWithCode(workshop.jobCode)).pipe(
          take(1),
          map((playerJob) => {
            if (!playerJob)
              return JobActions.openWorkshopFailedWrongJob({
                jobCode: workshop.jobCode,
              });
            else if (!canUseWorkshop(playerJob.xp)[workshop.rankJob])
              return JobActions.openWorkshopFailedRequiredXpLow({
                xp: playerJob.xp,
                requiredXp: RankJobXp[workshop.rankJob],
              });
            else return JobActions.openWorkshopSuccess({ workshop });
          }),
        ),
      ),
    ),
  );

  private _openWorkshopFailedWrongJob$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(JobActions.openWorkshopFailedWrongJob),
        tap(({ jobCode }) => {
          this._notificationService.addNotification(
            'error',
            `Vous n'avez pas le métier requis! (${JobCodeToNameEnum[jobCode]})`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _openWorkshopFailedRequiredXpLow$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(JobActions.openWorkshopFailedRequiredXpLow),
        tap(({ xp, requiredXp }) => {
          this._notificationService.addNotification(
            'error',
            `Expérience du métier trop bas! ${xp}<${requiredXp}XP`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _openWorkshopSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.openWorkshopSuccess),
      withLatestFrom(this._store.select(getRecipes)),
      concatMap(([{ workshop }, recipes]) =>
        [
          !recipes.length
            ? JobActions.displayedRecipes({ jobCode: workshop.jobCode })
            : null,
          HudDisplayActions.showHud({ name: 'jobs' }),
        ].filter((action) => !!action),
      ),
    ),
  );

  private _closeWorkshop$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.closeWorkshop),
      map(() => {
        return HudDisplayActions.hideHud({ name: 'jobs' });
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}
}
