import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FullscreenManagerService {
  public document: Document;

  constructor() {
    this.document = document;
  }

  public get fullscreenElement() {
    return document.fullscreenElement;
  }

  // Argument : True ou false
  // Résultat : Assigne une valeur précise à l'affichage du chat
  public switchFullscreen(): void {
    if (!this.fullscreenElement) {
      this.setFullScreen();
    } else {
      this.removeFullScreen();
    }
  }

  // Arguments : Valeur d'affichage
  // Résultat : Active l'affichage en plein écran
  public setFullScreen(): void {
    if (
      !this.fullscreenElement &&
      this.document.documentElement.requestFullscreen
    ) {
      this.document.documentElement.requestFullscreen();
    }
  }

  // Arguments : Valeur d'affichage
  // Résultat : Retire l'affichage en plein écran
  public removeFullScreen(): void {
    if (this.fullscreenElement && this.document.exitFullscreen) {
      this.document.exitFullscreen();
    }
  }
}
