import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { Warp } from '@t12/common/world/interfaces/warp.interface';
import { World } from '@t12/common/world/interfaces/world.interface';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/user/services/auth/authentication.service';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { Observable } from 'rxjs';
import { WarpPlayerDto } from '../../../../../../back/src/world/dto/warp-player.dto';

@Injectable({
  providedIn: 'root',
})
export class WorldDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
    private readonly _utilsService: UtilsService,
  ) {}

  // Argument : ID joueur, ID personnage
  // Résultat : Retourne les infos d'un personnage stocké en base de donnée
  public getWorld$(): Observable<World> {
    const playerId = this._utilsService.getSelect(getPlayerID);

    return this._http.get(`${environment('world').uri}`, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
      params: {
        playerId,
      },
    }) as Observable<World>;
  }

  public getNextWorld(
    scrollCode?: string,
  ): Observable<{ world: World; warp: Warp }> {
    const playerId = this._utilsService.getSelect(getPlayerID);
    const warpPlayerDto: WarpPlayerDto = {
      playerId,
      scroll: scrollCode,
    };

    return this._http.patch(`${environment(`world/warp`).uri}`, warpPlayerDto, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<{ world: World; warp: Warp }>;
  }
}
