import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Monster } from '@t12/common/characters/interfaces/monster.interface';
import { NPC } from '@t12/common/characters/interfaces/npc.interface';
import { IPlayer } from '@t12/common/characters/interfaces/player.interface';
import { Character } from '@t12/common/characters/types/character.type';
import { Looking } from '@t12/common/characters/types/looking.type';
import { QuestIcon } from '@t12/common/characters/types/quest-icon.type';
import { IContainer } from '@t12/common/container/interfaces/container.interface';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { PlayerJob } from '@t12/common/job/interfaces/player-job.interface';
import { PlayerJobs } from '@t12/common/job/interfaces/player-jobs.interface';
import { JobCode } from '@t12/common/job/types/job-code.type';
import { WorldPosition } from '@t12/common/world/interfaces/world-position.interface';
import { AttackKind } from '@t12/fight/types/attack-kind.type';
import { HitKind } from '@t12/fight/types/hit-kind.type';

export const CharactersActions = createActionGroup({
  source: 'CHARACTER',
  events: {
    Reset: emptyProps(),
    'Load player': emptyProps(),
    'Load player success': props<{ player: IPlayer }>(),
    'Init player success': emptyProps(),
    'Load player failed': props<{ error: HttpErrorResponse }>(),
    'Add player': props<{ id: number }>(),
    'Add player success': props<{ player: IPlayer }>(),
    'Add player failed': emptyProps(),
    'Remove player': props<{ id: number }>(),
    'Set characters': props<{ characters: (Monster | NPC)[] }>(),
    'Set characters success': emptyProps(),
    'Reset characters': emptyProps(),

    Move: props<{ idCharacter: number; direction: Looking; force?: true }>(),
    'Move step': props<{ idCharacter: number; direction: Looking }>(),
    'Idle step': props<{ idCharacter: number }>(),

    Attack: props<{ idCharacter: number; attackKind: AttackKind }>(),
    'Attack first step': props<{
      idCharacter: number;
      attackKind: AttackKind;
    }>(),
    'Attack second step': props<{
      idCharacter: number;
      attackKind: AttackKind;
    }>(),
    'Attack third step': props<{
      idCharacter: number;
      attackKind: AttackKind;
    }>(),

    'Attack try to hit': props<{
      attacker: Character;
      target: Character;
      attackKind: AttackKind;
    }>(),
    'Attack try to hit failed': props<{
      attackKind: AttackKind;
      hit: HitKind;
    }>(),

    'Attack hit': props<{
      attacker: Character;
      target: Character;
      attackKind: AttackKind;
      hit: HitKind;
    }>(),
    'Attack deal damage': props<{
      attacker: Character;
      target: Character;
      damage: number;
    }>(),
    'Add fight': props<{
      attacker: Character;
      target: NPC | Monster;
    }>(),

    'Npc death': props<{
      attacker: Character;
      target: NPC | Monster;
    }>(),
    'Npc death success': props<{
      attacker: Character;
      target: NPC | Monster;
    }>(),

    'Set player track id': props<{ trackId: number }>(),
    'Set position x y': props<{ idCharacter: number; y: number; x: number }>(),
    'Set looking': props<{ idCharacter: number; looking: Looking }>(),
    'Set face to player': props<{ idCharacter: number }>(),
    'Set can move': props<{ idCharacter: number; canMove: boolean }>(),

    'Add health': props<{ idCharacter: number; health: number }>(),
    'Set dead': props<{ id: number; dead: boolean }>(),
    'Add mana': props<{ idCharacter: number; mana: number }>(),
    'Add health mana': props<{
      idCharacter: number;
      health: number;
      mana: number;
    }>(),

    'Player death': emptyProps(),
    'Apply death sanction': props<{ worldPosition: WorldPosition }>(),

    'Reward fight': props<{
      target: NPC | Monster;
      xp: number;
      container: IContainer;
    }>(),
    'Reward xp': props<{ xp: number; notification?: boolean }>(),
    'Reward loot': props<{ container: IContainer }>(),
    'Add xp': props<{ xp: number }>(),
    'Level up': props<{ xp: number }>(),

    'Add gold': props<{ gold: number }>(),
    'Set state': props<{ idCharacter: number; state: 'fight' | 'passive' }>(),

    'Equip item': props<{ item: Item; oldEquipment?: Item }>(),
    'Unequip item': props<{ item: Item }>(),

    'Set quest icon': props<{ codeCharacter: string; questIcon?: QuestIcon }>(),

    'Add new job': props<{ job: PlayerJob }>(),
    'Remove job': props<{ job: PlayerJob }>(),
    'Set jobs': props<{ jobs: PlayerJobs }>(),
    'Set job xp': props<{ jobCode: JobCode; xp: number }>(),
  },
});
