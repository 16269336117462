import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { Monster } from '@t12/common/characters/interfaces/monster.interface';
import { NPC } from '@t12/common/characters/interfaces/npc.interface';
import { IPlayer } from '@t12/common/characters/interfaces/player.interface';

export const isPlayer = (
  character: IPlayer | Monster | NPC,
): character is IPlayer => {
  return character.type === CharacterKind.PLAYER;
};
