import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SocialsState } from '../index';

const selectRoot = createFeatureSelector<SocialsState>('socials');

export const getSocialsActiveTab = createSelector(
  selectRoot,
  ({ activeTab }: SocialsState) => activeTab,
);

export const getFriendRequests = createSelector(
  selectRoot,
  ({ friendRequests: { displayedFriends } }: SocialsState) => displayedFriends,
);

export const getFriendRequestsAmount = createSelector(
  selectRoot,
  ({ friendRequests: { list } }: SocialsState) => list.length,
);

export const getFriends = createSelector(
  selectRoot,
  ({ friends: { displayedFriends } }: SocialsState) => displayedFriends,
);

export const getFriendsCurrentPage = createSelector(
  selectRoot,
  ({ friends: { currentPage } }: SocialsState) => currentPage,
);

export const getFriendsMaxPage = createSelector(
  selectRoot,
  ({ friends: { maxPage } }: SocialsState) => maxPage,
);

export const isFriend = (name: string) =>
  createSelector(getFriends, (friends) => {
    return !!friends.find((f) => f.name === name);
  });
