import { createReducer, on } from '@ngrx/store';
import { HotkeyActions } from '../actions/hotkey.actions';
import { HotkeyState, initialHotkeyState } from '../index';

const keys = ['&', 'é', '"', "'", '('];

export const HotkeyReducer = createReducer(
  initialHotkeyState,

  on(HotkeyActions.addHotkey, (hotkeyState: HotkeyState[], { hotkey }) => {
    const alreadyAdded = hotkeyState.some(
      (hotkeyItem) => hotkeyItem.entity.code === hotkey.entity.code,
    );
    if (hotkeyState.length >= keys.length || alreadyAdded) {
      return hotkeyState;
    }

    return [...hotkeyState, { ...hotkey, key: keys[hotkeyState.length] }];
  }),

  on(
    HotkeyActions.setHotkeyAt,
    (hotkeyState: HotkeyState[], { hotkey, index }) =>
      hotkeyState.map((currentHotkey, i) =>
        i === index ? { ...hotkey, key: keys[index] } : currentHotkey,
      ),
  ),

  on(HotkeyActions.removeHotkey, (hotkeyState: HotkeyState[], { code }) => {
    return hotkeyState.filter((hotkey) => hotkey.entity.code !== code);
  }),

  on(HotkeyActions.setHotkeys, (_, { hotkeys }) => hotkeys),

  on(HotkeyActions.resetHotkeys, (_) => []),

  on(HotkeyActions.deleteHotkeys, (_) => []),
);
