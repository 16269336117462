import { Injectable } from '@angular/core';
import { Looking } from '@t12/common/characters/types/looking.type';
import { PlayerActionSocket } from '@t12/common/player/constants/player-action-socket.interface';
import { AttackKind } from '@t12/fight/types/attack-kind.type';
import { SocketService } from '@t12/utils/services/socket/socket.service';

@Injectable({
  providedIn: 'root',
})
export class PlayerSocketService {
  constructor(private readonly _socketService: SocketService) {}

  public movePlayerAction(id: number, direction: Looking) {
    const playerEmit: PlayerActionSocket = {
      id,
      action: { type: 'move', direction },
    };

    this._socketService.emit('player', playerEmit);
  }

  public attackPlayerAction(id: number, attackKind: AttackKind) {
    const playerEmit: PlayerActionSocket = {
      id,
      action: { type: 'attack', attackKind },
    };

    this._socketService.emit('player', playerEmit);
  }

  public playerLeaveWorldAction(id: number, worldCode: string) {
    const playerEmit = { id, worldCode };

    this._socketService.emit('player-leave-world', playerEmit);
  }

  public playerDeathAction(id: number) {
    const playerEmit = { id };

    this._socketService.emit('player-death', playerEmit);
  }
}
