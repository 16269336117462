import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { getPlayerItemInventory } from '@t12/inventory/store/selectors/inventory.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { ShopDbService } from '@t12/shop/services/shop-db/shop-db.service';
import { ShopActions } from '@t12/shop/store/actions/shop.actions';
import { switchMap, map, take, tap, catchError, of, throttleTime } from 'rxjs';

@Injectable()
export class ShopSellEffects {
  private _sellItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ShopActions.sellItem),
      throttleTime(200),
      switchMap(({ itemCode, amount }) =>
        this._store.select(getPlayerItemInventory(itemCode)).pipe(
          take(1),
          map((item) => {
            if (!item || item.amount < amount)
              return ShopActions.sellItemFailNotEnoughItem();
            else
              return ShopActions.sellItemSuccess({
                item: {
                  ...item,
                  price: Math.floor(item.price * 0.5) * amount,
                },
                amount,
              });
          }),
        ),
      ),
    ),
  );

  private _sellItemSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ShopActions.sellItemSuccess),
      tap(({ item, amount }) => {
        this._audioService.playSound('miscs', 'gold');
        this._notificationService.addNotification(
          'item',
          `-${amount} ${item.name} -> +${item.price} OR`,
          5000,
          item.img,
        );
      }),
      switchMap(({ item, amount }) =>
        this._shopDbService.sellItem(item.code, amount).pipe(
          take(1),
          switchMap(() => [
            InventoryActions.removeItemInInventory({
              itemCode: item.code,
              amount,
            }),
            CharactersActions.addGold({ gold: item.price * amount }),
          ]),
          catchError(() => of(ShopActions.sellItemFail())),
        ),
      ),
    ),
  );

  private _sellItemFailNotEnoughItem$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ShopActions.sellItemFailNotEnoughItem),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas assez d'exemplaire de l'objet",
          ),
        ),
      ),
    { dispatch: false },
  );

  private _sellItemFail$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ShopActions.sellItemFail),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _shopDbService: ShopDbService,
    private readonly _store: Store,
  ) {}
}
