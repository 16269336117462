export const HUD_DISPLAY_FEATURE_KEY = 'hud-display';

export interface HudDisplayState {
  bank: boolean;
  barInfos: boolean;
  chat: boolean;
  chatPreview: boolean;
  container: boolean;
  dialog: boolean;
  inventory: boolean;
  jobs: boolean;
  notification: boolean;
  playerInfos: boolean;
  presentation: boolean;
  quests: boolean;
  settings: boolean;
  shop: boolean;
  socials: boolean;
}

export const initialHudDisplayState: HudDisplayState = {
  bank: false,
  barInfos: false,
  chat: false,
  chatPreview: false,
  container: false,
  dialog: false,
  inventory: false,
  jobs: false,
  notification: false,
  playerInfos: false,
  presentation: false,
  quests: false,
  settings: false,
  shop: false,
  socials: false,
};
