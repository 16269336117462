import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import { getPlayerPositionInFront } from '@t12/characters/store/selectors/characters.selectors';
import { DayTime } from '@t12/common/world/enums/day-time.enum';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { WorldDbService } from '@t12/world/services/world-db/world-db.service';
import { WorldGeneratorService } from '@t12/world/services/world-generator/world-generator.service';
import { WorldActions } from '@t12/world/store/actions/world-actions';
import {
  catchError,
  map,
  switchMap,
  of,
  take,
  tap,
  concatMap,
  withLatestFrom,
  filter,
  delay,
} from 'rxjs';

@Injectable()
export class WorldEffects {
  private _loadWorld$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.loadWorld),
      switchMap(() =>
        this._dbWorldService.getWorld$().pipe(
          take(1),
          concatMap((world) => [WorldActions.loadWorldSuccess({ world })]),
          catchError((error) => of(WorldActions.loadWorldError({ error }))),
        ),
      ),
    ),
  );

  private _loadWorldSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.loadWorldSuccess),
      tap(({ world }) => {
        this._audioService.playBackgroundMusic(world.music || 'home');
      }),
      map(({ world }) => {
        const { characters } = world;
        const charactersWithId = characters.map((character) => ({
          ...character,
          idCharacter: this._worldService.getTrackId(),
          trackId: this._worldService.getTrackId(),
          dead: false,
        }));

        return CharactersActions.setCharacters({
          characters: charactersWithId,
        });
      }),
    ),
  );

  private _teleportTo$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.teleportTo),
      switchMap(({ scroll }) =>
        this._dbWorldService.getNextWorld(scroll).pipe(
          take(1),
          switchMap(({ world, warp }) => [
            WorldActions.teleportToSuccess({ world, warp }),
            scroll
              ? CharactersActions.setLooking({
                  idCharacter: 0,
                  looking: 'down',
                })
              : null,
          ]),
          filter((action) => !!action),
          catchError((error) => of(WorldActions.loadWorldError({ error }))),
        ),
      ),
    ),
  );

  private _pickItemSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.pickItemSuccess),
      withLatestFrom(this._store.select(getPlayerPositionInFront)),
      map(([{ amount }, { x, y }]) => {
        return WorldActions.removeItemTile({ x, y, amount });
      }),
    ),
  );

  private _dropItemSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.dropItemSuccess),
      withLatestFrom(this._store.select(getPlayerPositionInFront)),
      map(([{ item, amount }, { x, y }]) => {
        return WorldActions.addItemTile({
          x,
          y,
          item: {
            ...item,
            amount,
          },
        });
      }),
    ),
  );

  private _resetBlackout$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.setTime),
      filter(({ time }) => time === DayTime.BLACKOUT),
      delay(4000),
      map(() => {
        return WorldActions.setTime({ time: DayTime.DAY });
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _worldService: WorldGeneratorService,
    private readonly _dbWorldService: WorldDbService,
    private readonly _store: Store,
  ) {}
}
