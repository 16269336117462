import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChatState } from '@t12/chat/store';

const selectRoot = createFeatureSelector<ChatState>('chat');

export const getChat = createSelector(selectRoot, (chat: ChatState) => chat);

export const getActiveChat = createSelector(
  selectRoot,
  (chat: ChatState) => chat[chat.activeTab],
);

export const getChatLogs = createSelector(
  selectRoot,
  ({ chat }: ChatState) => chat,
);

export const getActiveTabChat = createSelector(
  selectRoot,
  ({ activeTab }: ChatState) => activeTab,
);

export const getScopeChat = createSelector(
  selectRoot,
  ({ scopeChat }: ChatState) => scopeChat,
);

export const getNewMessagesAmount = createSelector(
  selectRoot,
  ({ newMessages }: ChatState) => newMessages,
);

export const getPmPlayerName = createSelector(
  selectRoot,
  ({ pmPlayerName }: ChatState) => pmPlayerName,
);
