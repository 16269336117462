import { Position } from '@t12/common/characters/interfaces/position.interface';
import { Looking } from '@t12/common/characters/types/looking.type';

export const positionInFrontClose = (
  x: number,
  y: number,
  looking: Looking,
): Position =>
  ({
    right: { x: x + 1, y: y },
    up: { x, y: y - 1 },
    down: { x, y: y + 1 },
    left: { x: x - 1, y },
  })[looking];
