import { createReducer, on } from '@ngrx/store';
import { PointsStatsRace } from '@t12/creation-player/enums/points-stats-race.enum';
import { CreationPlayerActions } from '../actions/creation-player.actions';
import { CreationPlayerState, initialCreationPlayerState } from '../index';

export const CreationPlayerReducer = createReducer(
  initialCreationPlayerState,

  on(CreationPlayerActions.init, () => ({
    ...initialCreationPlayerState,
  })),

  on(
    CreationPlayerActions.incStepCreation,
    (creationPlayerState: CreationPlayerState, { value }) => ({
      ...creationPlayerState,
      step: creationPlayerState.step + value,
    }),
  ),

  on(
    CreationPlayerActions.setStepCreation,
    (creationPlayerState: CreationPlayerState, { step }) => ({
      ...creationPlayerState,
      step,
    }),
  ),

  on(
    CreationPlayerActions.loadItemsForCreationSuccess,
    (
      creationPlayerState: CreationPlayerState,
      { races, stats, personalities, divinities },
    ) => ({
      ...creationPlayerState,
      details: {
        ...creationPlayerState.details,
        races,
        stats,
        personalities,
        divinities,
      },
    }),
  ),

  on(
    CreationPlayerActions.setNamePlayer,
    (creationPlayerState: CreationPlayerState, { name }) => ({
      ...creationPlayerState,
      player: {
        ...creationPlayerState.player,
        name,
      },
    }),
  ),

  on(
    CreationPlayerActions.setGenderPlayer,
    (creationPlayerState: CreationPlayerState, { gender }) => ({
      ...creationPlayerState,
      player: {
        ...creationPlayerState.player,
        gender,
      },
    }),
  ),

  on(
    CreationPlayerActions.updateActiveRace,
    (creationPlayerState: CreationPlayerState, { race }) => ({
      ...creationPlayerState,
      player: {
        ...creationPlayerState.player,
        raceCode: race.code,
        stats: race.stats,
      },
      totalStats: PointsStatsRace[race.code],
    }),
  ),

  on(
    CreationPlayerActions.incAllowedNumberStats,
    (creationPlayerState: CreationPlayerState, { value }) => ({
      ...creationPlayerState,
      totalStats: creationPlayerState.totalStats + value,
    }),
  ),

  on(
    CreationPlayerActions.incPlayerStat,
    (
      creationPlayerState: CreationPlayerState,
      { stat, value, nbStatsReq },
    ) => ({
      ...creationPlayerState,
      player: {
        ...creationPlayerState.player,
        stats: {
          ...creationPlayerState.player.stats,
          [stat]: creationPlayerState.player.stats[stat] + value,
        },
      },
      totalStats: creationPlayerState.totalStats + nbStatsReq,
    }),
  ),

  on(
    CreationPlayerActions.addPlayerPersonality,
    (creationPlayerState: CreationPlayerState, { personality }) => ({
      ...creationPlayerState,
      player: {
        ...creationPlayerState.player,
        personalities: [
          ...creationPlayerState.player.personalities,
          personality,
        ],
      },
    }),
  ),

  on(
    CreationPlayerActions.removePlayerPersonality,
    (creationPlayerState: CreationPlayerState, { personality }) => ({
      ...creationPlayerState,
      player: {
        ...creationPlayerState.player,
        personalities: [...creationPlayerState.player.personalities].filter(
          (personalityItem) => personalityItem !== personality,
        ),
      },
    }),
  ),

  on(
    CreationPlayerActions.setPlayerDivinity,
    (creationPlayerState: CreationPlayerState, { divinity }) => ({
      ...creationPlayerState,
      player: {
        ...creationPlayerState.player,
        divinity,
      },
    }),
  ),

  on(
    CreationPlayerActions.removePlayerDivinity,
    (creationPlayerState: CreationPlayerState) => ({
      ...creationPlayerState,
      player: {
        ...creationPlayerState.player,
        divinity: null,
      },
    }),
  ),

  on(
    CreationPlayerActions.setSkinPlayer,
    (creationPlayerState: CreationPlayerState, { skin }) => ({
      ...creationPlayerState,
      player: {
        ...creationPlayerState.player,
        skin,
      },
    }),
  ),

  on(
    CreationPlayerActions.initNamesSuccess,
    (creationPlayerState: CreationPlayerState, { firstNames, lastNames }) => ({
      ...creationPlayerState,
      firstNames,
      lastNames,
    }),
  ),
);
