import { NgClass, NgIf, LowerCasePipe, UpperCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { TranslocoDirective, provideTranslocoScope } from '@jsverse/transloco';
import { LabelInfosPipe } from '../../pipes/label-infos/label-infos.pipe';

@Component({
  selector: 'app-slot-stat',
  templateUrl: './slot-stat.component.html',
  styleUrls: ['./slot-stat.component.less'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    LowerCasePipe,
    LabelInfosPipe,
    TranslocoDirective,
    UpperCasePipe,
  ],
  providers: [provideTranslocoScope('characters-creation/statistics')],
})
export class SlotStatComponent {
  @Input() svg: SafeHtml;
  @Input() bonus: boolean;
  @Input() reverse: boolean;
  @Input() hideValue: boolean;
  @Input() showOperator: boolean;
  @Input() imgName: string;
  @Input() urlImg: string;
  @Input() label: string;
  @Input() value: number;
  @Input() maxValue: number;
  @Input() border = true;
}
