import { LanguageItem } from '@t12/settings/types/language.type';

export const LANGUAGES: LanguageItem[] = [
  {
    code: 'fr',
    label: 'Français',
  },
  {
    code: 'en',
    label: 'English',
  },
];
