import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class LocalService {
  constructor(private storageService: StorageService) {}

  // Set the json data to local storage
  public setJsonValue(key: string, value: any): void {
    this.storageService.secureStorage.setItem(key, value);
  }

  // Get the json value from local storage
  public getJsonValue(key: string): any {
    return JSON.parse(this.storageService.secureStorage.getItem(key));
  }

  // Remove item from local storage
  public removeItem(key: string): void {
    return this.storageService.secureStorage.removeItem(key);
  }

  // Clear the local storage
  public clearToken(): void {
    return this.storageService.secureStorage.clear();
  }
}
