import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { HotkeyState } from '../index';

export const HotkeyActions = createActionGroup({
  source: 'HOTKEY',
  events: {
    'Add hotkey': props<{ hotkey: HotkeyState }>(),
    'Set hotkey at': props<{ hotkey: HotkeyState; index: number }>(),
    'Remove hotkey': props<{ code: string }>(),
    'Set hotkeys': props<{ hotkeys: HotkeyState[] }>(),
    'Reset hotkeys': emptyProps(),
    'Delete hotkeys': emptyProps(),
  },
});
