import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import { PlayerJob } from '@t12/common/job/interfaces/player-job.interface';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { JobDbService } from '@t12/jobs/services/job-db/job-db.service';
import { JobActions } from '@t12/jobs/store/actions/job.actions';
import { getPlayerJobWithCode } from '@t12/jobs/store/selectors/job.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { map, switchMap, tap, take, catchError, of } from 'rxjs';

@Injectable()
export class JobsEffects {
  private _learnJob$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.learnJob),
      switchMap(({ itemCode }) =>
        this._jobDbService.addJob(itemCode).pipe(
          take(1),
          map((job: PlayerJob) =>
            JobActions.learnJobSuccess({ job, itemCode }),
          ),
          catchError(() => of(JobActions.learnJobFail())),
        ),
      ),
    ),
  );

  private _learnJobSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.learnJobSuccess),
      tap(({ job }) => {
        this._notificationService.addNotification(
          'job',
          `Nouveau métier appris: ${job.name}`,
        );
      }),
      switchMap(({ job, itemCode }) => [
        InventoryActions.removeItemInInventory({ itemCode, amount: 1 }),
        CharactersActions.addNewJob({ job }),
      ]),
    ),
  );

  private _learnJobFail$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(JobActions.learnJobFail),
        tap(() => {
          this._notificationService.addNotification(
            'job',
            'Une erreur est survenue, contactez un administrateur.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _learnJobFailedReachedMax$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(JobActions.learnJobFailedReachedMax),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Vous ne pouvez apprendre que deux métiers d'artisanat.",
          );
        }),
      ),
    { dispatch: false },
  );

  private _learnJobFailedAlreadyKnow$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(JobActions.learnJobFailedAlreadyKnow),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Vous connaissez déjà ce métier.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _forgetJob$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.forgetJob),
      tap(({ job }) => {
        this._notificationService.addNotification(
          'job',
          `Vous avez oublié le métier: ${job.name}`,
        );
        this._jobDbService.removeJob(job.code).pipe(take(1)).subscribe();
      }),
      map(({ job }) => CharactersActions.removeJob({ job })),
    ),
  );

  private _updateJobXp$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.updateJobXP),
      switchMap(({ jobCode, playerJobXp: xp }) =>
        this._store.select(getPlayerJobWithCode(jobCode)).pipe(
          take(1),
          tap((playerJob) => {
            this._notificationService.addNotification(
              'job',
              `Vous avez gagné 1XP pour le métier : ${playerJob.name}`,
            );
          }),
          map(() => {
            return CharactersActions.setJobXp({
              jobCode,
              xp,
            });
          }),
        ),
      ),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _jobDbService: JobDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}
}
