import { Injectable } from '@angular/core';
import { getCharacters } from '@t12/characters/store/selectors/characters.selectors';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { Position } from '@t12/common/characters/interfaces/position.interface';
import { Character } from '@t12/common/characters/types/character.type';
import { Looking } from '@t12/common/characters/types/looking.type';
import { Tile } from '@t12/common/world/interfaces/tile.interface';
import { World } from '@t12/common/world/interfaces/world.interface';
import { SidesTiles } from '@t12/common/world/types/sides-tile.type';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { getWorldTileAt } from '@t12/world/store/selector/world.selectors';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorldGeneratorService {
  private _world$: BehaviorSubject<World> = new BehaviorSubject(null);
  private _trackId = 1;

  constructor(private readonly _utils: UtilsService) {}

  public getTrackId(): number {
    this._trackId++;
    return this._trackId;
  }

  // Argument : Numéro de la tuile, type du personnage qui bouge
  // Résultat : Vérifie que la tuile envoyée est accessible pour s'y déplacer
  public canStepOnTile(character: Character): boolean {
    const { x, y } = character.position;
    const currentTile = this._utils.getSelect(getWorldTileAt(x, y));
    if (!currentTile) return false;

    const directions = {
      up: { x, y: y - 1 },
      down: { x, y: y + 1 },
      right: { x: x + 1, y },
      left: { x: x - 1, y },
    };

    const nextPosition = directions[character.looking];
    const nextTile = this._utils.getSelect(
      getWorldTileAt(nextPosition.x, nextPosition.y),
    );
    if (!nextTile) return false;

    return !this._canMoveToNextTile(
      character,
      nextTile,
      currentTile.sides,
      nextPosition,
    );
  }

  // Argument : Index de la tuile, direction déplacement
  // Résultat : Vérifie si le déplacement entre deux tuiles est possible selon la direction du déplacement
  // TODO Inverser le boolean de cette méthode, vérifiez qu'on a accès et pas l'inverse wtf
  private _canMoveToNextTile(
    character: Character,
    nextTile: Tile,
    currentTileSides: SidesTiles = [false, false, false, false],
    { x, y }: Position,
  ): boolean {
    const characterFound = this._utils
      .getSelect(getCharacters)
      .find(
        (character) => character.position.x === x && character.position.y === y,
      );

    if (
      !nextTile ||
      nextTile.item ||
      nextTile.workshop ||
      nextTile.harvestPoint ||
      (nextTile.container && character.idCharacter === 0) ||
      (characterFound?.dead === false &&
        characterFound?.type !== CharacterKind.PLAYER)
    ) {
      return true;
    }

    let nextTileSides = nextTile.sides || [false, false, false, false];
    if (currentTileSides.length === 1)
      currentTileSides = [true, true, true, true];

    if (nextTileSides.length === 1) nextTileSides = [true, true, true, true];

    return this._isSideBlocked(
      character.looking,
      currentTileSides,
      nextTileSides,
    );
  }

  private _isSideBlocked(
    direction: Looking,
    currentTileSides: SidesTiles,
    nextTileSides: SidesTiles,
  ): boolean {
    if (direction === 'right') {
      return currentTileSides[1] || nextTileSides[3];
    } else if (direction === 'left') {
      return currentTileSides[3] || nextTileSides[1];
    } else if (direction === 'up') {
      return currentTileSides[0] || nextTileSides[2];
    } else if (direction === 'down') {
      return currentTileSides[2] || nextTileSides[0];
    }
  }
}
