import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PlayerFriendRequest } from '@t12/common/socials/interfaces/player-friend-request.interface';
import { PlayerFriend } from '@t12/common/socials/interfaces/player-friend.interface';
import { PlayerLogged } from '@t12/common/socials/interfaces/player-logged.interface';
import { SocialTab } from '../../enums/social-tab.enum';

export const SocialsActions = createActionGroup({
  source: 'SOCIALS',
  events: {
    'Set tab': props<{ tab: SocialTab }>(),

    'Get friends': emptyProps(),
    'Set friends': props<{ friends: PlayerFriend[] }>(),
    'Get friends failed': emptyProps(),
    'Inc friends page': props<{ inc: 1 | -1 }>(),
    'Update displayed friends': emptyProps(),

    'Add friend': props<{ playerFriend: PlayerFriend }>(),
    'Add friend failed': emptyProps(),
    'Player logged in': props<{ player: PlayerLogged }>(),
    'Friend logged in': props<{ player: PlayerLogged }>(),
    'Player logged out': props<{ player: PlayerLogged }>(),
    'Friend logged out': props<{ player: PlayerLogged }>(),

    'Set friend online status': props<{ name: string; online: boolean }>(),

    'Delete friend': props<{ name: string }>(),
    'Delete friend success': props<{ name: string }>(),
    'Delete friend failed': emptyProps(),

    'Get friend requests': emptyProps(),
    'Set friend requests': props<{ friendRequests: PlayerFriendRequest[] }>(),

    'Send friend request': props<{ name: string }>(),
    'Send friend request failed': emptyProps(),
    'Send friend request failed already friend': emptyProps(),
    'Send friend request failed not found': emptyProps(),
    'Send friend request failed already requested': emptyProps(),
    'Send friend request success': props<{ name: string }>(),

    'Accept friend request': props<{ name: string }>(),
    'Denied friend request': props<{ name: string }>(),
    'Denied friend request failed': emptyProps(),
    'Add friend request': props<{ friendRequest: PlayerFriendRequest }>(),
    'Remove friend request': props<{ name: string }>(),
    'Update displayed friend requests': emptyProps(),

    'Send pm': props<{ playerFriend: PlayerFriend }>(),
  },
});
