import { Routes } from '@angular/router';
import { AuthGuardService } from '@t12/user/services/auth/auth-guard.service';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@t12/user/user_routes').then((mod) => mod.User_routes),
  },
  {
    canActivate: [AuthGuardService],
    path: 'characters',
    loadChildren: () =>
      import('@t12/creation-player/characters_routes').then(
        (mod) => mod.CHARACTERS_ROUTES,
      ),
  },
  {
    canActivate: [AuthGuardService],
    path: 'game',
    loadChildren: () =>
      import('@t12/world/world_routes').then((mod) => mod.WORLD_ROUTES),
  },
];
