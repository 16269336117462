import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TimersManagerService {
  private _timersMove: Map<number, number> = new Map();
  private _timersFight: Map<number, number> = new Map();

  // Argument : ------
  // Résultat : Renvoie la map des timers de déplacement
  public getTimersMove(): Map<number, number> {
    return this._timersMove;
  }

  // Argument : ------
  // Résultat : Renvoie la map des timers de combats des NPC
  public getTimersFight(): Map<number, number> {
    return this._timersFight;
  }

  // Argument : ID du personnage
  // Résultat : Supprime le timer d'une patrouille
  public stopTimerMoveByID(id: number): void {
    const patrol = this._timersMove.get(id);
    if (patrol) {
      clearInterval(patrol);
      this._timersMove.delete(id);
    }
  }

  // Argument : ------
  // Résultat : Supprime tous les timers de patrouille
  public stopAllTimersMove(): void {
    this._timersMove.forEach((element) => {
      clearInterval(element);
    });
    this._timersMove = new Map();
  }

  // Argument : ID du combat
  // Résultat : Supprime le timer d'un combat
  public stopTimerFightByID(id: number): void {
    const fight = this._timersFight.get(id);
    if (fight) {
      clearInterval(fight);
      this._timersFight.delete(id);
    }
  }

  // Argument : ------
  // Résultat : Supprime tous les timers de combat
  public stopAllTimersFight(): void {
    this._timersFight.forEach((element) => {
      clearInterval(element);
    });
    this._timersFight = new Map();
  }
}
