import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPlayerPositionInFront } from '@t12/characters/store/selectors/characters.selectors';
import { getOpeningSoundContainer } from '@t12/container/constants/get-opening-sound-containter.cnstant';
import { ContainerActions } from '@t12/container/store/actions/container.actions';
import { JobCodeToNameEnum } from '@t12/jobs/enums/job-code-to-name.enum';
import { HarvestDbService } from '@t12/jobs/services/harvest-db/harvest-db.service';
import { JobActions } from '@t12/jobs/store/actions/job.actions';
import { getPlayerJobWithCode } from '@t12/jobs/store/selectors/job.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display.actions';
import { getHudContainer } from '@t12/overlay/store/selectors/hud-display.selectors';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { WorldActions } from '@t12/world/store/actions/world-actions';
import {
  concatMap,
  map,
  withLatestFrom,
  switchMap,
  tap,
  take,
  catchError,
  of,
} from 'rxjs';

@Injectable()
export class HarvestEffects {
  private _harvest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.harvest),
      withLatestFrom(this._store.select(getHudContainer)),
      switchMap(([{ harvestPoint }, isVisibleLootBox]) =>
        this._store.select(getPlayerJobWithCode(harvestPoint.jobCode)).pipe(
          take(1),
          map((playerJob) => {
            if (!playerJob) {
              return JobActions.harvestFailedWrongJob({
                jobCode: harvestPoint.jobCode,
              });
            }

            if (playerJob.xp < harvestPoint.requiredXp) {
              return JobActions.openWorkshopFailedRequiredXpLow({
                xp: playerJob.xp,
                requiredXp: harvestPoint.requiredXp,
              });
            }

            return isVisibleLootBox
              ? ContainerActions.pickItemContainer({})
              : JobActions.harvestSuccess({ harvestPoint });
          }),
        ),
      ),
    ),
  );

  private _harvestSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.harvestSuccess),
      tap(({ harvestPoint }) => {
        const openSong = getOpeningSoundContainer(harvestPoint.material);
        this._audioService.playSound('miscs', openSong, 'ogg');
      }),
      withLatestFrom(this._store.select(getPlayerPositionInFront)),
      switchMap(([{ harvestPoint }, position]) =>
        this._harvestDb.getHarvestRessources().pipe(
          take(1),
          concatMap(({ container, playerJobXp }) => {
            const actions = [
              ContainerActions.setContainer({ container, position }),
              HudDisplayActions.showHud({ name: 'container' }),
              playerJobXp
                ? JobActions.updateJobXP({
                    jobCode: harvestPoint.jobCode,
                    playerJobXp,
                  })
                : null,
            ];

            return actions.filter((action) => action !== null);
          }),
          catchError(() => of(JobActions.harvestFailed({ position }))),
        ),
      ),
    ),
  );

  private _harvestFailed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.harvestFailed),
      tap(() => {
        this._notificationService.addNotification(
          'error',
          'Ce point de récolte a expiré.',
        );
      }),
      map(({ position: { x, y } }) => {
        return WorldActions.removeEntity({ x, y, entity: 'harvestPoint' });
      }),
    ),
  );

  private _harvestFailedWrongJob$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(JobActions.harvestFailedWrongJob),
        tap(({ jobCode }) => {
          this._notificationService.addNotification(
            'error',
            `Vous n'avez pas le métier requis! (${JobCodeToNameEnum[jobCode]})`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _harvestFailedRequiredXpLow$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(JobActions.harvestFailedRequiredXpLow),
        tap(({ xp, requiredXp }) => {
          this._notificationService.addNotification(
            'error',
            `Expérience du métier trop bas! ${xp}<${requiredXp}XP`,
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _harvestDb: HarvestDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}
}
