import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContainerState } from '@t12/container/store';

const selectRoot = createFeatureSelector<ContainerState>('container');

export const getContainer = createSelector(
  selectRoot,
  (container: ContainerState) => container,
);

export const getContainerKind = createSelector(
  selectRoot,
  (container: ContainerState) => container.kind,
);

export const getContainerItems = createSelector(
  selectRoot,
  (container: ContainerState) => container.items,
);

export const getContainerCurrentPage = createSelector(
  selectRoot,
  ({ currentPage }) => currentPage,
);

export const getContainerMaxPage = createSelector(
  selectRoot,
  ({ maxPage }) => maxPage,
);

export const getPositionContainer = createSelector(
  selectRoot,
  ({ position }) => position,
);
